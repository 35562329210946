.CompetitionDurationInput__Wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.CompetitionDurationInput__Picker {
  position: relative;
}

.CompetitionDurationInput__Picker:first-child {
  margin-left: -8px;
}

.CompetitionDurationInput__Trigger {
  text-align: left;
  height: 40px;
  background-color: transparent;
  border-radius: 4px;
  color: var(--color-yellow-text);
  font-weight: 600;
  padding: 0 8px;
  white-space: nowrap;
  border: 2px solid transparent;
}

.CompetitionDurationInput__Picker_opened .CompetitionDurationInput__Trigger {
  border-color: var(--color-yellow);
}

.CompetitionDurationInput__Trigger:hover {
  background-color: var(--color-bg-light);
}

.CompetitionDurationInput__Dash {
  height: 2px;
  width: 16px;
  margin: 0 8px;
  background-color: var(--color-gray);
}

.CompetitionDurationInput__Popup {
  top: 50px;
}
