.chatPreviewLayout {
  &__wrapper {
    position: relative;
  }

  &__button {
    padding: 24px;
    display: block;
    width: 100%;
    text-align: left;
    cursor: default;
  }

  &__row {
    display: flex;
    align-items: baseline;
  }

  &__row:not(:first-child) {
    margin-top: 8px;
  }

  &__titleSlot {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__dateSlot {
    margin-left: auto;
    flex-shrink: 0;
  }

  &__messageSlot {
    margin-top: 8px;
    padding-right: 52px;
  }

  &__messageSlot > span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__bageSlot {
    position: absolute;
    right: 24px;
    bottom: 20px;
  }

  &__controlSlot {
    position: absolute;
    right: 24px;
    bottom: 20px;

    &--onHoverOnly {
      .chatPreviewLayout__wrapper:not(:hover) & {
        display: none;
      }
    }
  }
}
