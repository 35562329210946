.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 48px;
  min-width: 48px;
  padding: 6px 14px;
  border: solid 1px var(--color-gray-light);
  border-radius: 2px;
  cursor: pointer;
  user-select: none;

  &.selected {
    background-color: var(--color-yellow);
    border-color: var(--color-yellow);

    .count {
      color: var(--color-black);
    }
  }

  &.disabled {
    background-color: var(--color-bg-light);

    .title,
    .count {
      color: var(--color-gray);
    }
  }
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: var(--color-black);
}

.count {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--color-yellow);
}
