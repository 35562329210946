.wrapper {
  position: relative;
  display: flex;
}

.input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 44px;
  padding: 0 18px;
  border: solid 1px var(--color-gray-light);
  border-radius: 4px;
  cursor: pointer;
}

.popup {
  display: flex;
  width: 100%;
  padding: 8px 16px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 340px;
}

.popupContent {
  display: flex;
  flex-direction: column;
}

.arrow {
  display: inline;
  vertical-align: middle;
  margin-left: 5px;
  transition: transform 0.2s;
}

.arrow * {
  stroke: currentColor;
}

.arrow_up {
  transform: rotate(180deg);
}
