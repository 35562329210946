.SectionsForm__Wrapper {
}

.SectionsForm__Label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-white);
  border-radius: 8px;
  height: 56px;
  padding: 0 22px;
}
