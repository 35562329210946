@import './src/style/ui';

.CSSThemeProvider {
  --color-white: #ffffff;
  --color-black: #333333;
  --color-gray: #999999;
  --color-gray-light: #dfdfdf;
  --color-border: var(--color-gray-light);
  --color-border-hover: #bababa;
  --color-bg-light: #f5f5f5;
  --color-bg-yellow: #f7e5ad;
  --color-yellow: #f7a601;
  --color-yellow-hover: #f7c631;
  --color-yellow-text: #d99100;
  --color-red: #ea4139;
  --color-bg-red: #f7e0df;

  --navigation-item-fs: 16px;
  --nav-dl-indent: 48px;

  --call-btn-bg: var(--color-bg-light);
  --call-btn-bg-hover: var(--color-yellow);

  --card-call-btn-bg: var(--color-gray-light);
  --card-call-btn-bg-hover: var(--color-yellow);
  --call-btn-color: var(--color-gray);
  --call-btn-color-hover: var(--color-black);

  --appl-table-title-color: var(--color-black);

  --btn-size: 44px;
  --btn-radius: 4px;
  --btn-font-size: 16px;
  --btn-line-height: 24px;
  --btn-font-weight: 600;
  --btn-padding: 0 18px;

  --checkbox-unchecked-icon-display: none;
  --checkbox-size: 20px;
  --checkbox-radius: 4px;
  --checkbox-color: var(--color-gray);
  --checkbox-color-hover: var(--color-yellow);
  --checkbox-border-color: var(--checkbox-color);
  --checkbox-border-color-hover: var(--checkbox-color-hover);
  --checkbox-bg: transparent;
  --checkbox-bg-hover: transparent;

  --custom-select__button-font-weight: 600;

  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  height: inherit;
}
