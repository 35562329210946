.popup {
  width: 100%;

  &:before {
    content: none;
  }
}

.content {
  padding: 10px 16px;
  max-height: 192px;
  overflow-x: hidden;
  overflow-y: auto;

  > div:not(:first-child) {
    margin-top: 20px;
  }
}
