.employeeItemBtn {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 8px;
    color: var(--color-border-hover);
    cursor: pointer;

    &:hover {
      background-color: var(--color-white);
    }

    &--accent_default {
    }

    &--accent_critical {
      color: var(--color-red);
    }

    &--hoverAccent_default:hover {
      color: var(--color-black);
    }

    &--hoverAccent_critical:hover {
      color: var(--color-red);
    }
  }
}
