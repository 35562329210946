.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 56px;
}

.secondaryControls {
  display: flex;
  gap: 16px;
}

.primaryControls {
  display: flex;
  gap: 16px;
}

.waitersSelectControlSlot {
  width: 254px;

  [data-invalid='true'] {
    color: unset;
    border-color: var(--color-red);
  }
}

.editWaitersControlSlot {
  button {
    --btn-padding: 0 9px;
  }
}

.deleteControlSlot {
  button {
    --btn-padding: 0 9px;
  }
}

.cancelControlSlot {
}

.submitControlSlot {
}
