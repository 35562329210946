.shadow {
  position: relative;
}

.shadow::before {
  content: '';
  position: absolute;
  left: 0;
  height: 5px;
  width: 100%;
  opacity: 0;
  transition: opacity 0.2s;
}

.shadow--direction_down::before {
  bottom: -5px;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1) inset;
}

.shadow--direction_up::before {
  bottom: 0px;
  box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.1) inset;
}

.shadow--enable::before {
  opacity: 1;
}
