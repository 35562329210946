.CompetitionsArticle__Template {
  display: flex;
  align-items: flex-start;
  margin: auto;
}

.CompetitionsArticle__Template_edit {
}

.CompetitionsArticle__Content {
  width: 414px;
  background-color: white;
  box-shadow: 0 0 0 1px #dfdfdf;
}

/* Text input */

.CompetitionsArticle__TextInput {
  border: none;
  display: block;
  resize: none;
  width: 100%;
  outline: none;
}

.CompetitionsArticle__TextInput_title {
  font-size: 22px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--color-border);
}

.CompetitionsArticle__TextInput_title:focus-within {
  border-color: var(--color-yellow);
}

.CompetitionsArticle__TextInput_description {
  font-size: 16px;
}

/* Image */

.CompetitionsArticle__ImageWrapper {
  width: 100%;
  height: 207px;
}

.CompetitionsArticle__Image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.CompetitionsArticle__ImagePlaceholder {
  background-color: var(--color-bg-light);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Info */

.CompetitionsArticle__Info {
  padding: 32px 24px;
}

.CompetitionsArticle__Duration {
  font-size: 28px;
  line-height: 42px;
  font-weight: 600;
  color: var(--color-yellow-text);
}

.CompetitionsArticle__Title,
.CompetitionsArticle__TitlePlaceholder {
  word-wrap: break-word;
  line-height: 30px;
  font-size: 22px;
  margin-top: 8px;
}

.CompetitionsArticle__Description,
.CompetitionsArticle__DescriptionPlaceholder {
  word-wrap: break-word;
  font-size: 14px;
  line-height: 22px;
}

.CompetitionsArticle__Description {
  white-space: pre-wrap;
}

.CompetitionsArticle__TitlePlaceholder,
.CompetitionsArticle__DescriptionPlaceholder {
  color: var(--color-gray);
}

/* Actions */

.CompetitionsArticle__ActionsWrapper {
  position: sticky;
  top: 0;
  padding: 0 16px;
}

.CompetitionsArticle__ActionsButton {
  border: 2px solid var(--color-border-hover);
}

.CompetitionsArticle__ActionsButton:hover {
  border-color: var(--color-gray);
}

.CompetitionsArticle__ActionsButton * {
  stroke: var(--color-border-hover);
}
.CompetitionsArticle__ActionsButton:hover * {
  stroke: var(--color-gray);
}

.CompetitionsArticle__ActionsButton:not(:first-child) {
  margin-top: 16px;
}
