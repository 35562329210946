.new-messages-indicator-layout {
  &__wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    right: 28px;
    bottom: 28px;
  }

  &__bage-slot {
    position: absolute;
    top: -10px;
  }

  &__action-slot {
  }
}
