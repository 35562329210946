.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  margin: 4px 0;
  background-color: var(--color-bg-light);
  border: 1px solid var(--color-bg-light);
  border-radius: 8px;

  &:hover {
    .controlsGroup {
      visibility: visible;
    }
  }

  input {
    width: 100%;
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-light);
    border-radius: 4px;

    padding: 8px 17px;

    &[data-invalid='true'] {
      border-color: var(--color-red);
    }

    &:focus {
      border-color: var(--color-yellow);
    }
  }
}

.countInputGroup {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 12px;
}

.nameInputSlot {
  width: 276px;
}

.minCountInputSlot,
.maxCountInputSlot {
  width: 55px;

  input {
    text-align: center;

    padding-inline: 0;
  }
}

.controlsGroup {
  visibility: hidden;
  display: flex;
  margin-left: auto;
  gap: 8px;
}
