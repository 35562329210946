.messageTemplatesListItemLayout {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 6px 0;
  }

  &__primaryAction {
    width: 16px;

    .messageTemplatesListItemLayout__wrapper:not(:hover) & {
      visibility: hidden;
    }
  }

  &__messageSlot {
    margin-left: 2px;
  }

  &__secondaryAction {
    width: 24px;
    height: 24px;

    margin-left: 6px;

    .messageTemplatesListItemLayout__wrapper:not(:hover) & {
      visibility: hidden;
    }
  }
}
