.wrapper {
  display: flex;
  padding: 10px 20px;
  background-color: var(--color-bg-light);
  border-radius: 8px;
  grid-gap: 24px;
  text-align: left;
  cursor: pointer;

  &:hover {
    box-shadow: inset 0 0 0 2px var(--color-gray-light);
  }
  &.blacklisted {
    box-shadow: inset 0 0 0 2px var(--color-red);
  }
}

.cardNumber {
  width: 84px;
  flex-shrink: 0;
}

.phoneNumber {
  width: 138px;
  flex-shrink: 0;
}

.name {
  width: 100%;
}
