.BooleanInputControl {
  position: relative;
  display: inline-block;
}

.BooleanInputControl__Input {
  display: none;
}

.BooleanInputControl__Wrapper {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BooleanInputControl__Label {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 1px solid var(--color-gray);
  box-sizing: border-box;
  transition: 0.05s linear transform;
  cursor: pointer;
}

.BooleanInputControl__Wrapper:hover .BooleanInputControl__Label {
  transform: scale(1.5);
  border: 1px solid var(--color-yellow-hover);
}

.BooleanInputControl__Input:checked + .BooleanInputControl__Label {
  transform: scale(1.5);
  border-color: var(--color-yellow-hover);
  background-color: var(--color-yellow-hover);
}

.BooleanInputControl__Wrapper:hover
  .BooleanInputControl__Input:checked
  + .BooleanInputControl__Label {
  border-color: var(--color-yellow);
  background-color: var(--color-yellow);
}
