.toast {
  &__wrapper {
    position: fixed;
    top: 16px;
    right: 16px;

    background: var(--color-white);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-left: 2px solid var(--color-yellow);

    display: flex;
    align-items: stretch;

    z-index: 10;

    &:hover {
      .toast__closeArea {
        visibility: visible;
      }
    }
  }
  &__bodyArea {
    padding: 23px 16px 23px 24px;
  }
  &__titleSlot {
  }
  &__descriptionSlot {
    margin-top: 4px;
  }
  &__closeArea {
    padding: 5px;

    visibility: hidden;

    color: var(--color-gray);

    &:hover {
      background-color: var(--color-bg-light);
    }
  }
}
