@import './src/style/ui';

.AuthorizedScreen__Template {
  width: 100%;
  height: 100%;
  display: flex;
  @include mq-mobile {
    padding-top: $m-header-height;
  }
}

.AuthorizedScreen__Sidebar {
  background-color: white;
  position: relative;
  border-right: 1px solid var(--color-border);
  z-index: 1;
  min-width: 240px;
  max-width: 240px;
  --mobile-drawer-transform: translateX(100%);

  @include navigation-minimize {
    min-width: 120px;
    max-width: 120px;
  }
  @include mq-mobile {
    position: fixed;
    width: 100%;
    min-width: 100%;
    height: calc(100% - #{$m-header-height});
    transform: var(--mobile-drawer-transform);
    max-width: 100%;
    top: $m-header-height;
    right: 0;
    z-index: 100;
    --navigation-item-fs: 22px;
  }
}

.AuthorizedScreen__Content {
  flex-grow: 1;
  min-width: 0;
  min-height: 0;
  overflow-y: auto;
  @include mq-mobile {
    -webkit-overflow-scrolling: touch;
  }
}

.AuthorizedScreen__MobileSidebarOpened {
  --mobile-drawer-transform: translateX(0);
}
