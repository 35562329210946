.noActiveSectionsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.noActiveSectionsTitleLabel {
  color: var(--color-gray);
  font-size: 22px;
  font-weight: bold;
}
