.chatMessageLayout {
  &__wrapper {
    display: flex;
    flex-direction: column;

    padding: 0 20px;

    margin-top: 2px;

    &--align_left {
      align-items: flex-start;
    }

    &--align_right {
      align-items: flex-end;
    }
  }

  &__body {
    &:not(:first-child) {
      margin-top: 4px;
    }
  }

  &__dateLabelSlot {
    align-self: center;

    margin-top: 40px;
  }

  &__timeLabelSlot {
    margin-top: 10px;
    padding: 0 8px;
  }

  &__action-slot {
    display: inline-block;
    margin-right: 10px;
  }

  &__message-slot {
    display: inline-block;
  }
}
