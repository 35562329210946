.CropImageModal__Window {
  --footer-height: 100px;
  --cropper-min-height: 400px;
  --cropper-max-height: calc(100vh - var(--footer-height));
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.CropImageModal__Window_hidden {
  visibility: hidden;
}

.CropImageModal__Body {
  max-width: 1040px;
  background-color: var(--color-black);
}

.CropImageModal__Cropper {
  display: block !important;
  min-height: var(--cropper-min-height);
  max-height: var(--cropper-max-height);
  margin: auto;
}

.CropImageModal__Footer {
  height: var(--footer-height);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: white;
  grid-gap: 20px;
  padding: 0 28px;
  right: 50px;
  top: 50px;
}

.CropImageModal__Button {
}

.CropImageModal__Button:not(:first-child) {
  margin-left: 20px;
}
