.bookingTimelineLegendLayout {
  &__wrapper {
    padding-left: var(--bookingPageTrackLabelWidth);
  }

  &__itemsSlot {
    display: flex;

    > * {
      display: block;
      width: 50%;
    }
  }

  &__nowLineSlot {
    position: relative;
    z-index: 1;
  }

  &__nowLine {
    position: absolute;

    width: 2px;

    background: var(--color-yellow);

    height: 100vh;

    top: calc(100% + 8px);
  }
}
