.wrapper {
  display: flex;
  flex-direction: column;
  margin: 24px -24px;
  padding: 24px;
  border-radius: 8px;
  row-gap: 24px;
  background-color: var(--color-bg-light);
}

.searchBoxPanel {
  width: 100%;
  background-color: var(--color-bg-light);

  input {
    width: 100%;
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    background-color: var(--color-bg-light);
  }
}

.searchIconPanel {
  position: absolute;
  top: 12px;
  left: 0;
  color: var(--color-gray);
}

.searchResultPopup {
  display: flex;
  width: 100%;
  min-height: 44px;
  top: 100%;
}

.clientInfoPanel {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.label {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
}

.namePanel {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.phoneAndCardPanel {
  display: flex;
  column-gap: 20px;
}

.phonePanel {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.cardPanel {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.error {
  color: var(--color-red);
}
