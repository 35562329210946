.wrapper {
  position: relative;
}

.triggerSlot {
}

.popupSlot {
  min-width: 100%;
  position: absolute;
  z-index: 1;
  top: calc(100% + 10px);
}

.popupSlot__up {
  top: auto;
  bottom: calc(100% + 10px);
}
