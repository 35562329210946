.CompetitionsFeedItem__Wrapper {
  width: 100%;
  padding: 0;
  background-color: white;
  transition: width 0.1s, box-shadow 0.1s;
  text-align: left;
  padding: 24px;
  border-left: 2px solid transparent;
}

.CompetitionsFeedItem__Wrapper_selected {
  background-color: var(--color-bg-light);
  border-left-color: var(--color-yellow);
}

.CompetitionsFeedItem__Pretitle,
.CompetitionsFeedItem__Title,
.CompetitionsFeedItem__TitlePlaceholder {
  word-wrap: break-word;
  line-height: 24px;
}

.CompetitionsFeedItem__Pretitle {
  color: var(--color-yellow-text);
  font-weight: 600;
}

.CompetitionsFeedItem__Title,
.CompetitionsFeedItem__TitlePlaceholder {
  margin-top: 7px;
}

.CompetitionsFeedItem__Wrapper_selected .CompetitionsFeedItem__Title,
.CompetitionsFeedItem__Wrapper_selected
  .CompetitionsFeedItem__TitlePlaceholder {
  font-weight: 600;
}

.CompetitionsFeedItem__TitlePlaceholder {
  color: var(--color-gray);
}

.CompetitionsFeedItem__Bage {
  float: right;
}
