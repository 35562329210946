.CustomTextarea__Wrapper {
  position: relative;
  z-index: 0;
}

.CustomTextarea__Textarea,
.CustomTextarea__HiddenValue {
  padding: var(--folder-head-padding);
  line-height: var(--folder-head-line-height);
}

.CustomTextarea__Textarea,
.CustomTextarea__HiddenValue {
  display: block;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
}

.CustomTextarea__Textarea {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  resize: none;
  border: none;
  outline: none;
  color: var(--text-color);
  background-color: transparent;
}

.CustomTextarea__Textarea::placeholder {
  color: #959dad;
}

.CustomTextarea__HiddenValue {
  word-wrap: break-word;
  white-space: pre-wrap;
  width: 100%;
  visibility: hidden;
}
