html,
body {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button,
a {
  font-family: unset;
}

button {
  outline: none;
  border: none;
  font-size: inherit;
  background-color: transparent;
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

* {
  box-sizing: border-box;
}

svg {
  vertical-align: middle;
}
