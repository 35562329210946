.CompetitionParticipants__Wrapper {
  padding: 24px 40px 40px 40px;
}

.CompetitionParticipants__Table {
  border-spacing: unset;
  width: 800px;
}

.CompetitionParticipants__Header {
}

.CompetitionParticipants__HeaderCell {
  text-align: left;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}

.CompetitionParticipants__BodyRow {
}

.CompetitionParticipants__DataCell,
.CompetitionParticipants__HeaderCell {
  padding: 16px 20px 16px 20px;
  border-bottom: 1px solid var(--color-border);
}

.CompetitionParticipants__DataCell:nth-child(1),
.CompetitionParticipants__DataCell:nth-child(2) {
  white-space: nowrap;
}

.CompetitionParticipants__DataCell:nth-child(2) {
  width: 200px;
}

.CompetitionParticipants__DataCell:nth-child(3) {
  display: flex;
  align-items: baseline;
  width: 400px;
}

.CompetitionParticipants__LogButton {
  margin-left: auto;
}

.CompetitionParticipants__LogButton svg {
  vertical-align: middle;
}

.CompetitionParticipants__LogButton:hover svg * {
  fill: var(--color-border-hover);
}

/* STATUS SELECT */

.CompetitionParticipants__StatusSelect {
  display: inline;
}

.CompetitionParticipants__StatusSelect_participationRequested {
  --custom-select__button-color: var(--color-yellow-text);
}
.CompetitionParticipants__StatusSelect_participationConfirmed {
  --custom-select__button-color: var(--color-black);
}
.CompetitionParticipants__StatusSelect_cancelationRequested {
  --custom-select__button-color: var(--color-red);
}
.CompetitionParticipants__StatusSelect_cancelationConfirmed {
  --custom-select__button-color: var(--color-gray);
}
