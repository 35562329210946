.Modal__Window {
  background-color: white;
  width: 512px;
  margin: auto;
}

.Modal__Body {
  padding: 40px 44px 0 44px;
}

.Modal__Title {
  font-size: 22px;
  line-height: 30px;
}

.Modal__Description {
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
}

.Modal__Footer {
  display: flex;
  justify-content: flex-end;
  grid-gap: 20px;
  padding: 28px;
  right: 50px;
  top: 50px;
}
