.Popup {
  white-space: initial;
  display: block;
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 10px 0;
}

.Popup::before {
  content: '';
  position: absolute;
  top: -5px;
  left: 50%;
  background-color: white;
  box-shadow: 1px -1px 3px 0 rgba(0, 0, 0, 0.05);
  width: 10px;
  height: 10px;
  transform: translate(-50%) rotate(-45deg);
}
