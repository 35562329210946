.modalWindow {
  display: flex;
  flex-direction: column;
  margin-right: 0;
  width: 724px;
  height: 100%;
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
  margin: 20px;
}

.closeBtn {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: var(--color-gray);
  }
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px 56px;
  overflow-x: hidden;
  overflow-y: auto;
}

.titleLabel {
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 12px;
}

.addWaiterBtnPanelWrapper {
  display: flex;
  margin: 20px 0;
}

.addWaiterBtnPanel {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 15px;
  cursor: pointer;
}

.addWaiterBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.addWaiterLabel {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-yellow);
}

.waitersContainer {
  display: flex;
  flex-direction: column;
  margin-right: -56px;
  padding-right: 56px;
  overflow-y: auto;
  max-height: 72vh;
}

.modalFooter {
  display: flex;
  align-items: center;
  padding: 30px 56px;
  grid-gap: 16px;
}
