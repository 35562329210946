.dndAnchor {
  &__wrapper {
    display: inline-block;

    padding: 2px 0;

    border-radius: 2px;

    &:hover {
      background: var(--color-gray-light);
    }
  }

  &__icon {
    color: var(--color-gray);
  }
}
