.wrapper {
  display: flex;
  flex-direction: column;
}

.labelTextSlot {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: var(--color-gray);
  text-transform: uppercase;
  margin-bottom: 6px;
  padding: 0 20px;
}

.itemsSlot {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}
