.CompetitionsPreview__Template {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.CompetitionsPreview__Content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 26px;
}

.CompetitionsPreview__FooterWrapper {
  display: flex;
  align-items: baseline;
  padding: 26px;
}

.CompetitionsPreview__SubmitDraftButton {
}

.CompetitionsPreview__SubmitButton {
  margin-left: 20px;
}

.CompetitionsPreview__CancelButton {
  margin-left: auto;
}

/* Tabs */

.CompetitionsPreview__Tabs {
  height: 60px;
  border-bottom: 1px solid var(--color-border);
  white-space: nowrap;
}

.CompetitionsPreview__Tab {
  display: inline-block;
  height: 100%;
  margin: 0 24px;
  color: var(--color-gray);
  font-weight: 600;
  border-bottom: 2px solid transparent;
  border-top: 2px solid transparent;
}

.CompetitionsPreview__Tab_active {
  border-bottom-color: var(--color-yellow);
  color: var(--color-black);
}

.CompetitionsPreview__Bage {
  display: inline-block;
  margin-left: 1em;
}

/* Preloader */

.CompetitionsPreview__Preloader {
  --spinner-color: var(--color-yellow);

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
