.Modal__Overlay {
  position: fixed;
  display: flex;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 10, 10, 0.5);
  overflow-y: auto;
  padding: 60px 20px;
}

.Modal__Overlay_toRight {
  padding-left: 640px;
}

.Modal__Overlay_noPadding {
  padding: 0;
}
