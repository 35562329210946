.chats-navigation {
  &__wrapper {
    height: 100vh;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: 60px auto;
  }

  &__header {
    padding: 24px 24px 0 24px;
  }

  &__body {
    overflow: auto;

    > *:first-child {
      height: 100%;
    }
  }
}

.chats-content {
  &__wrapper {
    height: 100vh;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: auto 1fr auto;
  }

  &__header {
  }

  &__body {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    > *:first-child {
      margin-top: auto;
    }
  }

  &__footer {
    padding: 20px 21px 35px 21px;

    textarea {
      max-height: 10em;

      // Перебивает стили от autosize. Нужно, чтобы после очистки
      // инпута не показывались кнопки скроллбара.
      overflow: auto !important;
    }
  }
}

.message-templates {
  &__wrapper {
    height: 100vh;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: auto 1fr auto;

    background-color: var(--color-bg-light);
  }

  &__header {
  }

  &__body {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    > *:first-child {
      margin-top: auto;
    }
  }

  &__footer {
    padding: 26px 20px 37px 20px;

    textarea {
      max-height: 10em;

      // Перебивает стили от autosize. Нужно, чтобы после очистки
      // инпута не показывались кнопки скроллбара.
      overflow: auto !important;
    }
  }
}
