.Spinner {
  animation: rotate 0.75s linear infinite;
  margin: auto;
  vertical-align: middle;
}

.Spinner * {
  fill: var(--spinner-color);
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
