.date-line-item {
  position: relative;
  width: 51px;
  height: 44px;
  background-color: var(--color-white);
  cursor: pointer;
  user-select: none;
  border-bottom: 1px solid transparent;

  &:not(:first-child) {
    border-left: 1px solid var(--color-gray-light);
  }

  &:hover {
    border-bottom-color: var(--color-yellow);
  }

  &.active {
    background-color: var(--color-yellow);
  }
}

.date-line-item__day-of-week,
.date-line-item__day {
  width: 100%;
  position: absolute;
  text-align: center;
  line-height: 1em;
}

.date-line-item__day-of-week {
  top: 5px;
}

.date-line-item__day {
  top: 21px;
}
