.ArticlePreviewContent__Shadow {
  position: relative;
  display: flex;
  flex-basis: 100%;
  width: 100%;
  overflow-y: auto;
}

.ArticlePreviewContent__Shadow::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.1) inset;
  opacity: 0;
  transition: opacity 0.2s;
}

.ArticlePreviewContent__Shadow_active::after {
  opacity: 1;
}

.ArticlePreviewContent__Scrollable {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  padding: 26px;
}
