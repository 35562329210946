.CompetitionsPage__Template {
  display: flex;
  width: 100%;
  height: 100%;
}

.CompetitionsPage__LeftSection {
  flex: 1;
  max-width: 320px;
  border-right: 1px solid var(--color-border);
}

.CompetitionsPage__LeftSection {
  background-color: white;
}

.CompetitionsPage__RightSection {
  flex: 1;
  min-width: 0;
}

.CompetitionsPage__RightSectionFallback {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.CompetitionsPage__RightSectionFallbackClickableArea {
  padding: 0;
}

.CompetitionsPage__RightSectionFallbackText {
  font-weight: 600;
}

.CompetitionsPage__RightSectionFallbackButton {
  margin-bottom: 24px;
}

.CompetitionsPage__RightSectionFallbackClickableArea:hover
  .CompetitionsPage__RightSectionFallbackButton {
  background-color: var(--color-yellow-hover);
  border-color: var(--color-yellow-hover);
  color: var(--color-black);
}
