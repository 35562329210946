.bookingTimelineTrackItemLayout {
  &__wrapper {
    width: 100%;
    height: 100%;
    border-left: 1px solid #dfdfdf;

    &--borderStyling_solid {
      border-left-style: solid;
    }
    &--borderStyling_dashed {
      border-left-style: dashed;
    }

    &:hover:not(:disabled) {
      background: rgba(247, 166, 1, 0.05);
      border: 1px solid var(--color-yellow);
    }
    &--over:not(:disabled) {
      background: rgba(0, 0, 0, 0.05);
      border: 1px solid var(--color-yellow);
    }
  }
}
