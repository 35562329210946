.date-line {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.date-input-slot {
}

.date-line-panel {
  display: flex;
}

.date-line-container {
  border-radius: 4px;
  overflow: hidden;
  display: flex;
}

.date-line-nav-slot {
  margin: 0 8px;
}
