@import './src/style/ui';

.ClipboardData__Wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: bottom;
  position: relative;
  z-index: 0;
  font-size: inherit;
  max-width: 100%;
  padding: 0 8px;

  &:hover {
    background: rgba(223, 223, 223, 0.5);
  }
}

.ClipboardData__Text,
.ClipboardData__Input {
  border-radius: 4px;
  font-size: inherit;
}

.ClipboardData__Text {
  position: relative;
  z-index: 0;
}

.ClipboardData__Input {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  border: none;
  outline: none;
  background: transparent;
  font-size: inherit;
  font-family: inherit;
  cursor: pointer;
}

.ClipboardData__Popup {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  background: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-size: 12px;
  padding: 5px 8px;
  white-space: pre;
}

.ClipboardData__Popup::after {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: -5px;
  left: 50%;
  background-color: white;
  box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.1);
  width: 10px;
  height: 10px;
  transform: translate(-50%) rotate(-45deg);
}
