.Bage__Wrapper {
  text-align: center;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
  background-color: var(--color-yellow-hover);
  color: var(--color-black);
  font-size: 12px;
  font-weight: 600;
}
