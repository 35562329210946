.Bage {
  color: var(--text-color);
}

.Bage_themeGold {
  --text-color: var(--color-black);
  --background-color: var(--color-yellow);
}

.Bage_themeSilver {
  --text-color: var(--color-black);
  --background-color: var(--color-gray-light);
}

.Bage_themeBlack {
  --text-color: var(--color-white);
  --background-color: var(--color-black);
}

.Bage__Wrapper {
  display: inline-block;
  padding: 1px 4px;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: var(--background-color);
}

.Bage__Slot {
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
}
