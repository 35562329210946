.bookingPageLayout {
  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 0 0 0;
    background-color: var(--color-bg-light);
    overflow: hidden;

    --bookingPageTrackLabelWidth: 120px;
    --bookingPageTrackHeight: 44px;
  }

  &__headerSlot {
    padding: 0 20px;
    flex-shrink: 0;
  }

  &__timelineLegendSlot {
    padding: 0 20px;
    margin-top: 33px;
    flex-shrink: 0;
  }

  &__timelineGroupsWrapper {
    overflow: auto;
    margin-top: 9px;
    height: 100%;
  }

  &__timelineGroupsSlot {
    padding: 0 20px 20px 20px;

    > *:not(:first-child) {
      margin-top: 16px;
    }
  }
}
