.ReviewNotes__Wrapper {
  display: flex;
  flex-direction: column;
  box-sizing: content-box;
  background-color: white;
  border-left: 1px solid var(--color-border);
}

.ReviewNotes__Title {
  height: 60px;
  flex-shrink: 0;
  flex-grow: 0;
  color: var(--color-black);
  font-size: 16px;
  font-weight: 600;
  vertical-align: top;
  padding: 24px 32px 18px 32px;
}

.ReviewNotes__Content {
  height: calc(100vh - 60px);
}

.ReviewNotes__Input {
  display: block;
  padding: 24px 32px;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: inherit;
  font-family: inherit;
  resize: none;
}

.ReviewNotes__Input:disabled {
  background-color: transparent;
}

.ReviewNotes__Input::placeholder {
  color: var(--color-gray);
}
