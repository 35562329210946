@import './src/style/ui';

.Checkbox__Wrapper {
  display: inline-block;
  vertical-align: middle;
}

.Checkbox__Input {
  display: none;
}

.Checkbox__Box {
  --color: var(--checkbox-color);
  --border-color: var(--checkbox-border-color);
  --bg: var(--checkbox-bg);

  display: flex;
  height: var(--checkbox-size);
  width: var(--checkbox-size);
  border-radius: var(--checkbox-radius);
  color: var(--color);
  border: 2px solid var(--border-color);
  background-color: var(--bg);
  cursor: pointer;
}

.Checkbox__Box:hover {
  --color: var(--checkbox-color-hover);
  --border-color: var(--checkbox-border-color-hover);
  --bg: var(--checkbox-bg-hover);
}

.Checkbox__CheckIcon {
  display: block;
  margin: auto;
  max-height: 60%;
}

.Checkbox__CheckIcon * {
  fill: currentColor;
}

.Checkbox__Input:not(:checked) + .Checkbox__Box .Checkbox__CheckIcon {
  display: var(--checkbox-unchecked-icon-display);
}
