.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 8px 0;
}

.titlePanel {
  display: flex;
  align-items: center;
  grid-gap: 12px;
}

.checkBox {
}

.title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.itemContainer {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 8px;
}
