.wrapper {
}

.wrapper__theme_labelOnTheTop {
  .labelSlot {
  }

  .inputSlot {
    margin-top: 12px;
  }
}

.wrapper__theme_labelOnTheRight {
  display: flex;
  gap: 16px;
  align-items: center;

  .labelSlot {
    order: 1;
  }

  .inputSlot {
    order: 0;
  }
}
