.ThreeColumnsPageLayout {
}

.ThreeColumnsPageLayout__Wrapper {
  width: 100%;
  height: 100%;

  display: grid;

  grid-template-columns: 320px 1px 584px 1px minmax(296px, auto);
}

.ThreeColumnsPageLayout__FirstColumnSlot {
}

.ThreeColumnsPageLayout__SecondColumnSlot {
}

.ThreeColumnsPageLayout__ThirdColumnSlot {
}

.ThreeColumnsPageLayout__DelimiterSlot {
}
