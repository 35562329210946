.toggler {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 174px;
  height: 44px;
  border-radius: 4px;
  background-color: #ededed;
  user-select: none;
  cursor: pointer;

  &.active {
    background-color: var(--color-yellow);
  }
}
