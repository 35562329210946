.RectangleButton {
  position: relative;
  z-index: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  vertical-align: middle;
  color: inherit;
  text-decoration: none;
  height: var(--btn-size);
  min-width: var(--btn-size);
  border-radius: var(--btn-radius);
  font-size: var(--btn-font-size);
  line-height: var(--btn-line-height);
  font-weight: var(--btn-font-weight);
  padding: var(--btn-padding);
}

.RectangleButtonIcon > svg {
  font-size: 1.4em;

  .RectangleButton.iconWithLabel & {
    margin-right: 0.4em;
  }
}

.RectangleButton:active:not(:disabled) {
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.1) inset;
}

.RectangleButton_preloader {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.RectangleButton_preloader .RectangleButton__Label {
  visibility: hidden;
}

.RectangleButton__Label {
}

.RectangleButton:active:not(:disabled) .RectangleButton__Label {
  transform: translateY(1px);
}

.RectangleButton__Preloader {
  position: absolute;
}

.RectangleButton_primary {
  background-color: var(--color-yellow);
  color: var(--color-black);
}

.RectangleButton_primary.RectangleButton_disabled {
  background-color: var(--color-border);
  cursor: default;
}

.RectangleButton_primary:hover:not(:disabled),
.RectangleButton_primary:focus:not(:disabled),
.RectangleButton_primary:active:not(:disabled),
.RectangleButton_primary.RectangleButton_preloader:not(:disabled) {
  background-color: var(--color-yellow-hover);
}

.RectangleButton_secondary,
.RectangleButton_destructive {
  border: 1px solid var(--color-border);
}

.RectangleButton_secondary:hover:not(:disabled),
.RectangleButton_secondary:focus:not(:disabled),
.RectangleButton_secondary:active:not(:disabled) {
  border-color: var(--color-yellow-hover);
}

.RectangleButton_destructive:hover:not(:disabled),
.RectangleButton_destructive:focus:not(:disabled),
.RectangleButton_destructive:active:not(:disabled) {
  border-color: var(--color-red);
  color: var(--color-red);
}
