.wrapper {
  display: flex;
  flex-direction: column;
  margin: 18px 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  margin-bottom: 6px;
}

.nameInputSlot {
  input {
    width: 280px;
    border-bottom: 1px solid var(--color-gray-light);
    padding: 8px 0;

    font-weight: 600;

    &[data-invalid='true'] {
      border-color: var(--color-red);
    }

    &:focus {
      border-color: var(--color-yellow);
    }
  }
}

.headerControls {
  display: flex;
  align-items: center;
  color: var(--color-border-hover);
}

.footer {
  display: flex;
  align-items: center;
  margin: 20px 0;
}
