.bookingWaiterItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 8px;
  margin: 4px 0;
  background-color: var(--color-bg-light);
  border-radius: 8px;
  border: 1px solid transparent;

  input {
    display: flex;
    width: 100%;
    padding-left: 17px;
  }
}

.bookingWaiterItem__invalid {
  border-color: var(--color-red);
}

.fioLabel {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-left: 17px;
}

.btnPanel {
  display: flex;
  align-items: center;
  justify-content: center;
}
