.ReviewsPage__Wrapper {
  display: grid;
  grid-template-columns: 320px auto;
  height: 100vh;
}

.ReviewsPage__Feed {
  background-color: white;
  border-right: 1px solid var(--color-border);
}

.ReviewsPage__Preview {
  display: grid;
  grid-template-columns: minmax(580px, auto) 300px;
}

.ReviewsPage__Preloader,
.ReviewsPage__Fallback {
  display: flex;
  height: 100%;
  width: 100%;
}

.ReviewsPage__Fallback {
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.ReviewsPage__Spinner {
  margin: auto;

  --spinner-color: var(--color-yellow);
}

.ReviewsPage__Fallback {
  color: var(--color-gray);
}
