.SettingsForm__Wrapper {
  margin-top: 22.5px;
}

.SettingsForm__Fieldset {
  padding: 0;
  margin: 0;
  border: none;
}

.SettingsForm__FieldsetInner {
  width: 100%;
  display: grid;
  grid-gap: 5px;
}

.SettingsForm__Footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.SettingsForm__Footer > *:not(:last-child) {
  margin-right: 20px;
}

.SettingsForm__Legend {
  position: relative;
  display: flex;
  margin-bottom: 10px;
}

.SettingsForm__LegendTitle,
.SettingsForm__LegendError {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.SettingsForm__LegendTitle {
  color: var(--color-black);
}

.SettingsForm__LegendError {
  color: var(--color-red);
}

.SettingsForm__LegendSlot {
  margin-left: auto;
  flex-shrink: 0;
}

.SettingsForm__DeleteButton {
  position: absolute;
  left: -55px;
  color: var(--color-gray);
  height: 44px;
  width: 44px;
  font-size: 24px;
  padding: 0;
  border-radius: 4px;
  top: -12px;
}

.SettingsForm__DeleteButton:hover {
  color: var(--color-red);
  background-color: var(--color-white);
}
