.bookingTimelineGroupLayout {
  &__wrapper {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.04);
  }

  &__headerSlot {
  }

  &__bodySlot {
    &--collapsed {
      display: none;
    }
  }
}
