.bookingTimelineGridLayout {
  &__wrapper {
    display: flex;

    background-color: white;
    border-top: 1px solid #dfdfdf;
  }

  &__body {
    position: relative;

    width: 100%;

    overflow: hidden;
  }

  &__labelsSlot {
    width: var(--bookingPageTrackLabelWidth);
    flex-shrink: 0;
  }

  &__tracksSlot {
  }
}
