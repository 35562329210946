.NewsArticle__Template {
  margin: auto;
  display: flex;
  align-items: flex-start;
}

.NewsArticle__Template_edit {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 73px;
  align-items: flex-start;
}

.NewsArticle__Content {
  width: 414px;
  background-color: white;
  box-shadow: 0 0 0 1px #dfdfdf;
}

/* Text input */

.NewsArticle__TextInput {
  border: none;
  display: block;
  resize: none;
  width: 100%;
  outline: none;
}

.NewsArticle__TextInput_title {
  font-size: 22px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--color-border);
}

.NewsArticle__TextInput_title:hover {
  border-color: var(--color-border-hover);
}

.NewsArticle__TextInput_title:focus-within {
  border-color: var(--color-yellow);
}

.NewsArticle__TextInput_description {
  margin-top: 18px;
  font-size: 16px;
}

/* Image */

.NewsArticle__ImageWrapper {
  width: 100%;
  height: 414px;
}

.NewsArticle__Image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.NewsArticle__ImagePlaceholder {
  background-color: var(--color-bg-light);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Info */

.NewsArticle__Info {
  padding: 24px;
}

.NewsArticle__Title,
.NewsArticle__TitlePlaceholder {
  word-wrap: break-word;
  line-height: 30px;
  font-size: 22px;
}

.NewsArticle__Description,
.NewsArticle__DescriptionPlaceholder {
  word-wrap: break-word;
  margin-top: 18px;
  font-size: 16px;
}

.NewsArticle__Description {
  white-space: pre-wrap;
}

.NewsArticle__TitlePlaceholder,
.NewsArticle__DescriptionPlaceholder {
  color: var(--color-gray);
}

/* Actions */

.NewsArticle__ActionsWrapper {
  padding: 0 16px;
  position: sticky;
  top: 0;
}

.NewsArticle__ActionsButton {
  border: 2px solid var(--color-border-hover);
}

.NewsArticle__ActionsButton:hover {
  border-color: var(--color-gray);
}

.NewsArticle__ActionsButton * {
  stroke: var(--color-border-hover);
}
.NewsArticle__ActionsButton:hover * {
  stroke: var(--color-gray);
}

.NewsArticle__ActionsButton:not(:first-child) {
  margin-top: 16px;
}
