.Section {
  width: 100%;
  height: 100%;
}

.Section_name_primary {
  background-color: var(--color-white);
}

.Section_name_secondary {
  background-color: var(--color-bg-light);
}
