.ShadowContainer__Wrapper {
  position: relative;
}

.ShadowContainer__Wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 5px;
  width: 100%;
  opacity: 0;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1) inset;
  transition: opacity 0.2s;
}

.ShadowContainer__Wrapper_scrolled::before {
  opacity: 1;
}
