.SwitchInput__Wrapper {
  display: inline-block;
}

.SwitchInput__Checkbox {
  display: none;
}

.SwitchInput__Slider {
  width: 50px;
  height: 32px;
  background-color: var(--color-gray-light);
  border-radius: 20px;
  padding: 3px;
  box-sizing: border-box;
  transition: background-color 0.2s;
}

.SwitchInput__Checkbox:checked + .SwitchInput__Slider {
  background-color: var(--color-yellow);
}

.SwitchInput__Slider::before {
  display: block;
  content: '';
  height: 26px;
  width: 26px;
  background-image: linear-gradient(0deg, #f5f5f5 0%, #ffffff 100%);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  transition: transform 0.2s;
}

.SwitchInput__Checkbox:checked + .SwitchInput__Slider::before {
  transform: translateX(18px);
}
