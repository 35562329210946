@import './src/style/ui';

$sh: 2px;

.MobileHeader__Wrapper {
  height: $m-header-height;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  align-items: center;
  background: #fff;
  z-index: $index-max;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 0 var(--nav-dl-indent);
  @include mq-mobile {
    padding: 0 30px;
  }
}

.MobileHeader__DrawerButton {
  width: 21px;
  height: 14px;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);

  span {
    background-color: #000;
    position: absolute;
    border-radius: 2px;
    transition: 0.3s cubic-bezier(0.3, 0.5, 0.2, 1.4);
    width: 100%;
    height: $sh;

    &:nth-child(1) {
      top: 0;
      left: 0;
    }
    &:nth-child(2) {
      top: 50%;
      margin-top: -$sh/2;
      left: 0;
      opacity: 1;
    }
    &:nth-child(3) {
      bottom: 0;
      left: 0;
    }
  }
}

.MobileHeader__DrawerButton_collapsed span:nth-child(1) {
  transform: rotate(45deg);
  margin-top: -$sh/2;
  top: 50%;
}
.MobileHeader__DrawerButton_collapsed span:nth-child(2) {
  opacity: 0;
}
.MobileHeader__DrawerButton_collapsed span:nth-child(3) {
  transform: rotate(-45deg);
  margin-top: -$sh/2;
  top: 50%;
}
