.EmployeeGroup {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 17px 0 5px;
}

.EmployeeGroup__Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6px 0 22px;
}

.EmployeeGroup__Body {
  display: flex;
  flex-direction: column;
}

.EmployeeGroup__Title {
  padding: 17px 0;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.EmployeeGroup__AddBtn {
  width: 32px;
  height: 32px;
  margin-right: 6px;
}

.EmployeeGroup__AddBtn:hover {
  background-color: var(--color-yellow-hover);
  border-color: var(--color-yellow-hover);
  color: var(--color-black);
}
