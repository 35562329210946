.CompetitionParticipantLog__Window {
  position: relative;
  z-index: 0;
  background-color: white;
  width: 480px;
  margin: auto;
}

.CompetitionParticipantLog__CloseButton {
  position: absolute;
  top: 18px;
  right: 18px;
}

.CompetitionParticipantLog__CloseIcon {
}

.CompetitionParticipantLog__Body {
  padding: 48px 60px;
}

.CompetitionParticipantLog__ItemWrapper {
}

.CompetitionParticipantLog__ItemWrapper:not(:first-child) {
  margin-top: 36px;
}

.CompetitionParticipantLog__ItemTitle,
.CompetitionParticipantLog__ItemBody {
  font-size: 16px;
  line-height: 24px;
}

.CompetitionParticipantLog__ItemTitle {
  font-weight: 600;
}
