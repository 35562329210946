.TextInputControl {
  display: block;
  border: none;
  outline: none;

  padding: 0;

  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;

  color: inherit;

  line-height: inherit;
}

.TextInputControl_textarea {
  resize: none;
  white-space: pre-wrap;
  overflow-wrap: break-word;

  height: 1em;
}

.TextInputControl_input {
}

/* THEMES */

/* Chat */

.TextInputControl {
  background-color: transparent;
}

.TextInputControl_themeChat {
  display: block;
  padding: 16px 22px;
  box-sizing: content-box;
  background-color: var(--color-bg-light);
}

.TextInputControl_themeChat::placeholder {
  color: var(--color-gray);
}
