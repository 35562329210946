.AudienceInput__Wrapper {
  width: 175px;
  position: sticky;
  top: 0;
}

.AudienceInput__Header {
  font-weight: 600;
}

.AudienceInput__Body {
  margin-top: 24px;
}

.AudienceInput__OptionLabel {
  display: flex;
  cursor: pointer;
}

.AudienceInput__OptionLabel:not(:first-child) {
  margin-top: 20px;
}

.AudienceInput__OptionText {
  flex: 1;
  min-width: 0;
}
