.CustomInput {
  border: none;
  outline: none;
  color: var(--color-black);
}

.CustomInput::placeholder {
  color: var(--color-gray);
}

.CustomInput:hover:not(:disabled) {
  border-color: var(--color-border-hover);
}
.CustomInput:focus:not(:disabled) {
  border-color: var(--color-yellow);
}

.CustomInput_underlined {
  border-bottom: 1px solid var(--color-border);
  padding: 7px 0;
}

.CustomInput_surrounded {
  border: 1px solid var(--color-border);
  border-radius: 4px;
  padding: 0 17px;
  font-size: 16px;
  height: 44px;
}
