.PhoneButton_mobile {
  flex: 1;
  min-width: 0;
  max-width: 343px;
  --color-border: var(--card-call-btn-bg);
  background-color: var(--card-call-btn-bg);
  white-space: nowrap;

  &:hover {
    --color-border: var(--card-call-btn-bg-hover);
    background-color: var(--card-call-btn-bg-hover);
  }
}

.PhoneButton_desktop {
  flex-shrink: 0;
  margin-left: auto;
  border-radius: 50%;
  background-color: var(--call-btn-bg);
  color: var(--call-btn-color);
  height: 44px;
  width: 44px;

  &:hover {
    background-color: var(--card-call-btn-bg-hover);
    color: var(--color-black);
  }
}
