.bookingTimelineReservationLayout {
  &__wrapper {
    min-height: var(--bookingPageTrackHeight);

    &--status_past,
    &--status_current {
      --backgroundColor: rgba(218, 246, 244, 1);
      --borderColor: rgba(47, 183, 150, 1);
    }
    &--status_future {
      --backgroundColor: rgba(207, 236, 255, 1);
      --borderColor: rgba(60, 150, 255, 1);
    }
    &--status_urgent {
      --backgroundColor: rgba(233, 227, 254, 1);
      --borderColor: rgba(130, 95, 255, 1);
    }

    position: absolute;
  }

  &__card {
    height: calc(100% - 1px);

    background: var(--backgroundColor);
    border-left: 2px solid var(--borderColor);
    border-radius: 2px;
    padding: 2px 8px;
    cursor: pointer;

    overflow: hidden;

    span {
      white-space: nowrap;
    }
  }

  &__title {
    line-height: 1;
  }

  &__details {
    line-height: 1;

    display: flex;
    align-items: center;
  }

  &__time {
  }

  &__participantsIcon {
    margin-left: 8px;
  }

  &__participantsCount {
    margin-left: 2px;
  }

  &__comment {
    margin-left: 8px;
  }

  &__popup {
    display: none;

    position: absolute;

    z-index: 1;

    top: calc(100% + 16px);
    left: 3px;

    .bookingTimelineReservationLayout__wrapper:hover & {
      display: block;
    }

    &::before {
      content: '';

      position: absolute;
      top: -10px;
      left: 10px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid white;
    }

    span {
      white-space: nowrap;
    }
  }
}
