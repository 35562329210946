.ContactInfoLayout {
}

.ContactInfoLayout__Wrapper {
  display: flex;
  width: 100%;
  padding: 24px 42px;
}

.ContactInfoLayout__InfoBlock {
}

.ContactInfoLayout__ActionsBlock {
  margin-left: auto;
  display: flex;
  gap: 16px;
}

.ContactInfoLayout__CallActionSlot {
}

.ContactInfoLayout__BookingActionSlot {
}

.ContactInfoLayout__FullNameSlot {
}

.ContactInfoLayout__PhoneSlot {
  margin-top: 8px;
}
