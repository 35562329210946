.Notification__Wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1;
}

.Notification__Wrapper_overlay {
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
}

.Notification__Popup {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  background-color: var(--color-yellow-hover);
  padding-left: 48px;
  padding-right: 40px;
}

.Notification__CloseButton {
  margin-left: auto;
  background: none;
}
