.ApplicationsPage__Wrapper {
  width: 100%;
  min-height: 100%;
}

.ApplicationsPage__Preloader {
  --spinner-color: var(--color-yellow);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 40px;
}
