$body-font-family: 'Open Sans', Arial, sans-serif;
$body-line-height: 18px;
$body-font-size: 13px;

$m-header-height: 60px;
$index-max: 100;

$font-weight-black: 900;
$font-weight-bold: 700;
$font-weight-medium: 600;
$font-weight-regular: 500;
$font-weight-light: 300;

$border-color: #dfdfdf;

$cl-red: #dd2424;
$cl-white: #fff;
$cl-blue: #19b7e8;
$cl-green: #24c121;
$cl-black: #181820;
$cl-secondary: #6a6b76;
$cl-placeholder: #a0a7b6;

$app-xl: 1440px;
$app-lg: 1024px;
$app-md: 992px;
$app-sm: 768px;
$app-xs: 480px;
$app-se: 380px;

$nav-dl-indent: 48px;
