.messageTemplatesActionsLayout {
  &__wrapper {
    display: flex;
    align-items: center;

    > *:not(:first-child) {
      margin-left: 12px;
    }
  }
}
