.contentEditable {
  &__wrapper {
  }

  &__input {
    display: inline-block;

    outline: none;
    border: none;

    &:empty:before {
      content: attr(data-placeholder);
      color: var(--color-gray);
    }

    &--theme_chat-message {
      padding: 16px 22px;
    }
    &--disabled {
      color: var(--color-gray);
    }
  }
}
