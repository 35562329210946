.SignInPage__Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.SignInPage__Content {
  height: 20em;
  margin: auto;
  padding: 1em;
}

.SignInPage__Logo {
  margin-bottom: 48px;
}

.SignInPage__Form {
}

.SignInPage__Fieldset {
  display: grid;
  grid-gap: 15px;
  margin-bottom: 12.5px;
}

.SignInPage__Input {
}

.SignInPage__SubmitButton {
  margin-top: 12.5px;
  width: 100%;
}

.SignInPage__Error {
  color: var(--color-red);
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 0;
}
