.ReviewPreview__Scrollable {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}

.ReviewPreview__Content {
  padding: 80px;
  max-width: 580px;
  overflow-y: auto;
  color: var(--color-black);
  margin-left: auto;
  margin-right: auto;
}

.ReviewPreview__Title {
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  color: var(--color-black);
  margin-top: 28px;
}

.ReviewPreview__InfoTable {
  width: 100%;
  margin-top: 28px;
}

.ReviewPreview__InfoRow {
  display: grid;
  grid-template-columns: 150px auto;
}

.ReviewPreview__InfoRow:not(:first-child) {
  margin-top: 16px;
}

.ReviewPreview__InfoLabel,
.ReviewPreview__InfoText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ReviewPreview__InfoLabel {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding-right: 27px;
}

.ReviewPreview__Message {
  margin-top: 32px;
}

.ReviewPreview__MessageLabel {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.ReviewPreview__MessageText {
  white-space: pre-wrap;
  word-break: break-word;
  margin-top: 16px;
}

.ReviewPreview__Status {
}

.ReviewPreview__Status_accent {
  --custom-select__button-color: var(--color-red);
}

.ReviewPreview__Status_secondary {
  --custom-select__button-color: var(--color-black);
}
