.wrapper {
  margin: 12px 0;
}

.toggleAllOrEveryDaysPanel {
  display: flex;
  align-items: center;
  margin: 32px 0;
}

.toggleAllOrEveryDaysBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 174px;
  height: 44px;
  border-radius: 4px;
  background-color: #ededed;
  user-select: none;
  cursor: pointer;

  &.active {
    background-color: var(--color-yellow);
  }
}

.header {
  display: flex;
  align-items: center;
  grid-gap: 65px;
  margin-bottom: 12px;
}

.content {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
}

.perDayPeriodField {
  display: flex;

  align-items: center;

  gap: 16px;
}

.perDayPeriodField__inputSlot {
}

.perDayPeriodField__labelSlot {
}
