.wrapper {
  border-radius: 50%;
  height: 44px;
  width: 44px;
}

.wrapper__theme_primary {
  color: var(--color-gray);

  &:hover {
    background-color: var(--color-yellow-hover);
    color: var(--color-black);
  }
}

.wrapper__theme_accent {
  background-color: var(--color-yellow);
  color: var(--color-black);

  &:hover {
    background-color: var(--color-yellow-hover);
  }
}

.wrapper__theme_secondary {
  color: var(--color-gray);

  &:hover {
    background-color: var(--color-white);
    color: var(--color-black);
  }
}

.iconSlot {
}
