.CreateButton {
  --font-size: 18px;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  font-family: inherit;
  font-size: var(--font-size);
  color: var(--color-yellow);
  border: 2px solid var(--color-yellow);
  border-radius: 50%;
  padding: 0;
}
