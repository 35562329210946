.employeeListHeader__wrapper {
  display: flex;
  padding: 0 22px;

  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
}

.employeeListHeader__columnName {
  width: 227px;
  margin-right: 72px;
}

.employeeListHeader__columnNumber {
  width: 44px;
  margin-right: 116px;
}

.employeeListHeader__columnLogin {
  width: 98px;
  margin-right: 337px;
}

.employeeListBody__wrapper {
  width: max-content;
  min-width: 740px;
}
