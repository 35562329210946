.message-box {
  position: relative;

  box-sizing: border-box;

  border: 1px solid transparent;

  border-radius: 8px;

  &--theme_primary,
  &--theme_secondary,
  &--theme_deleted,
  &--theme_template,
  &--theme_template-input {
    padding: 16px 22px;
  }

  &--theme_primary,
  &--theme_secondary,
  &--theme_editing {
    max-width: 440px;
  }

  &--theme_primary {
    border-color: var(--color-bg-yellow);
    background-color: var(--color-bg-yellow);
  }

  &--theme_secondary {
    border-color: var(--color-bg-light);
    background-color: var(--color-bg-light);
  }

  &--theme_input {
    display: flex;
    width: 100%;
    background-color: var(--color-bg-light);
    padding: 2px;
  }

  &--theme_input > textarea {
    width: 100%;
    padding: 6px 22px;
    align-self: center;
  }

  &--theme_input > button {
    margin-left: auto;
    align-self: flex-end;
    flex-shrink: 0;
  }

  &--theme_input-large {
    width: 100%;
    background-color: var(--color-bg-light);
    padding: 16px 22px;
  }

  &--theme_template,
  &--theme_template-input {
    min-width: 64px;
    max-width: 240px;

    padding: 8px 12px;

    background-color: white;
  }

  &--theme_template {
  }

  &--theme_template:hover {
    border-color: var(--color-gray-light);
  }

  &--theme_template-input {
    border-color: var(--color-yellow);
  }

  &--theme_editing {
    border-color: var(--color-yellow);
    background-color: white;
  }
}
