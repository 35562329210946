.MembersPage__Wrapper {
  width: 100%;
  max-width: 904px;
}

.MembersPage__InputSlot {
  padding: 36px 20px 0 20px;
}

.MembersPage__InputSlot input {
  width: 100%;
}

.MembersPage__ContentSlot {
  padding: 0 20px 20px 20px;
}
