.MembersTableItemLayout__IconSlot {
  font-size: 24px;
  color: var(--color-gray);
  align-items: center;
  display: flex;
}

@media (min-width: 1024px) {
  .MembersTableItemLayout__Wrapper {
    background: #f5f5f5;
    border-radius: 8px;
    height: 56px;
    padding: 0 18px;

    display: flex;
    align-items: center;
  }

  .MembersTableItemLayout__CardNumberSlot {
    margin-left: 16px;
  }

  .MembersTableItemLayout__PhoneNumberSlot {
    margin-left: 40px;
  }

  .MembersTableItemLayout__FullNameSlot {
    margin-left: 32px;
  }

  .MembersTableItemLayout__StatusLabelSlot {
    margin-left: auto;
  }

  .MembersTableItemLayout__StatusInputSlot {
    margin-left: 20px;
  }
}

@media (max-width: 1024px) {
  .MembersTableItemLayout__Wrapper {
    background: #f5f5f5;
    border-radius: 8px;
    padding: 16px;

    display: grid;
    grid-template-areas:
      'icon card-number status-label status-label'
      'full-name full-name full-name full-name'
      'phone-number phone-number phone-number status-input';
    row-gap: 16px;
    column-gap: 10px;
    grid-template-columns: 40px 0.5fr 1fr 0.5fr;

    --btn-size: 54px;
  }

  .MembersTableItemLayout__IconSlot {
    grid-area: icon;
  }

  .MembersTableItemLayout__CardNumberSlot {
    grid-area: card-number;
  }

  .MembersTableItemLayout__PhoneNumberSlot {
    grid-area: phone-number;
  }

  .MembersTableItemLayout__PhoneNumberSlot > *:last-child {
    background: #dfdfdf;
    border-radius: 8px;
    height: var(--btn-size);
    font-weight: 600;
    width: 100%;
  }

  .MembersTableItemLayout__PhoneNumberSlot > *:last-child:hover {
    background: #f7a601;
  }

  .MembersTableItemLayout__FullNameSlot {
    grid-area: full-name;
  }

  .MembersTableItemLayout__StatusLabelSlot {
    grid-area: status-label;
    text-align: end;
  }

  .MembersTableItemLayout__StatusInputSlot {
    grid-area: status-input;
    text-align: end;
  }
}
