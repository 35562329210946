.content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 8px;
}

.formSlot {
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.titleSlot {
}

.addButtonSlot {
}
