@import './src/style/ui';

.Navigation__Wrapper {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 180px 0 52px;
  overflow: auto;

  --item-height: 24px;

  @include mq-mobile {
    padding-top: 78px;
  }
}

.Navigation__Items {
}

.Navigation__Items_top {
}

.Navigation__Items_bottom {
  margin-top: auto;
}

.Navigation__ItemWrapper {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.Navigation__Item {
  display: block;
  line-height: var(--item-height);
  text-decoration: none;
  padding-left: var(--nav-dl-indent);
  padding-right: 40px;
  @include navigation-minimize {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.Navigation__Label {
  position: relative;
  font-size: var(--navigation-item-fs);
  @include navigation-minimize {
    display: none;
  }
}

.Navigation__Icon {
  svg {
    font-size: 24px;
  }

  @include mq-mobile {
    display: none;
  }
  @include mq-desktop-l-min {
    display: none;
  }
}

.Navigation__Logo {
  position: absolute;
}

.Navigation__Logo_Mobile {
  top: 64px;
  left: 50%;
  transform: translateX(-50%);
  @include mq-mobile {
    display: none;
  }
  @include mq-desktop-l-min {
    display: none;
  }
}

.Navigation__Logo_Desktop {
  top: 56px;
  left: var(--nav-dl-indent);
  @include mq-mobile {
    display: none;
  }
  @include navigation-minimize {
    display: none;
  }
}

.Navigation__Item_active {
  font-weight: 600;
}

.Navigation__Item {
  margin-bottom: 40px;
  @include mq-mobile {
    margin-bottom: 48px;
  }
}

.Navigation__Item:hover:before,
.Navigation__Item_active::before {
  content: '';
  position: absolute;
  left: 0;
  transform: translateY(calc(var(--item-height) / 2));
  width: 24px;
  height: 2px;
  background-color: var(--color-yellow);
}

.Navigation__SignOutLabel {
  @include navigation-minimize {
    display: none;
  }
}

.Navigation__SignOutButton {
  padding: 0;
  text-align: left;
  align-self: flex-start;
  font-size: var(--navigation-item-fs);
  height: var(--item-height);
  width: 100%;
  cursor: pointer;
  padding-left: var(--nav-dl-indent);
  @include navigation-minimize {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.Navigation__Item,
.Navigation__SignOutButton {
  color: var(--color-black);
}

.Navigation__SignOutButton:hover,
.Navigation__Item:hover,
.Navigation__Item_active {
  color: var(--color-black);
}

.Navigation__Bage {
  position: absolute;
  left: 100%;
  top: 50%;
  margin-left: 7px;
  transform: translateY(-50%);
  @include navigation-minimize {
    margin-left: -12px;
    margin-top: -12px;
  }
}
