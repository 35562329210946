.action-control {
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;

  &--hidden {
    visibility: hidden;
  }

  &--theme_round-primary,
  &--theme_round-primary-alt,
  &--theme_round-secondary,
  &--theme_round-secondary-alt,
  &--theme_round-floating,
  &--theme_round-cancel {
    display: flex;

    justify-content: center;
    align-items: center;

    border-radius: 50%;
    width: 44px;
    height: 44px;

    font-size: 24px;
  }

  &--theme_round-primary:hover:not(:disabled),
  &--theme_round-primary-alt:hover:not(:disabled),
  &--theme_round-secondary:hover:not(:disabled),
  &--theme_round-secondary-alt:hover:not(:disabled) {
    background: var(--color-yellow);
    color: var(--color-black);
  }

  &--theme_round-primary {
    background: var(--color-yellow-hover);
  }

  &--theme_round-primary-alt {
    background: var(--color-white);
  }

  &--theme_round-secondary {
    background: var(--color-bg-light);
  }

  &--theme_round-secondary-alt {
    background: transparent;
    color: var(--color-border-hover);
  }

  &--theme_round-cancel {
    background: var(--color-white);
  }

  &--theme_round-cancel:hover {
    color: var(--color-red);
  }

  &--theme_round-floating {
    background-color: white;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  }
  &--theme_round-small {
    padding: 4px;
    border-radius: 50%;
  }

  &--theme_square-delete {
    height: 24px;
    width: 24px;

    color: var(--color-border-hover);

    border-radius: 2px;

    &:hover {
      background: var(--color-bg-red);
      color: var(--color-red);
    }
  }

  &--theme_invisible {
    display: contents;

    text-align: inherit;
    font-size: inherit;
    font-weight: inherit;

    line-height: inherit;

    color: inherit;
  }
}
