.EmployeeItem {
  display: flex;
  align-items: center;
  height: 56px;
  margin: 4px 0;
  padding: 16px 6px 16px 22px;
  border-radius: 8px;
  background: var(--color-bg-light);

  &:hover .EmployeeItem__ButtonsContainer {
    visibility: visible;
    opacity: 1;
  }

  &.EmployeeItem__Blocked {
    background: var(--color-white);
    color: var(--color-border-hover);

    &:hover {
      background: var(--color-bg-light);
    }
  }

  --custom-select__button-font-weight: 400;
}

.EmployeeItem__Name {
  width: 227px;
  line-height: 24px;
}

.EmployeeItem__Number {
  width: 44px;
  margin-left: 72px;
}

.EmployeeItem__Login {
  width: 98px;
  margin-left: 116px;
}

.EmployeeItem__ButtonsContainer {
  margin-left: 128px;
  display: flex;
  align-items: center;
  visibility: hidden;
  opacity: 0;
}
