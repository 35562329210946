.NewsFeed__Wrapper {
  --wrapper-height: 100%;
  --header-height: 100px;
  --content-height: calc(var(--wrapper-height) - var(--header-height));

  position: relative;
  z-index: 0;
  height: var(--wrapper-height);
  border-right: 1px solid var(--color-border);
  flex: 1;
  max-width: 400px;
}

/* Header */

.NewsFeed__Header {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--header-height);
  padding: 40px;
  padding-bottom: 16px;
}

/* Content */

.NewsFeed__Scrollable {
  height: var(--content-height);
}

.NewsFeed__Items {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 36px 0;
  min-height: 100%;
}

.NewsFeed__Fallback {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--color-gray);
  font-weight: 600;
}

/* Create item button */

.NewsFeed__CreateItemButton {
  margin-left: auto;
}

.NewsFeed__CreateItemButton:hover {
  background-color: var(--color-yellow-hover);
  border-color: var(--color-yellow-hover);
  color: var(--color-black);
}

/* Preloader */

.NewsFeed__Preloader {
  --spinner-color: var(--color-yellow);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-grow: 1;
  margin: 36px 0;
}
