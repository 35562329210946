.employeeForm__legend {
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--color-black);
}

.employeeForm__legendTitle {
  margin-bottom: 15px;

  &.error {
    color: var(--color-red);
  }
}
