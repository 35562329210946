.ReviewsFeed__Wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.ReviewsFeed__Title {
  height: 60px;
  flex-shrink: 0;
  flex-grow: 0;
  color: var(--color-black);
  font-size: 16px;
  font-weight: 600;
  vertical-align: top;
  padding: 24px 24px 18px 24px;
}

.ReviewsFeed__Content {
  height: 100%;
  overflow-y: auto;
}

.ReviewsFeed__Preloader {
  display: flex;
  padding: 20px 0;
}

.ReviewsFeed__Spinner {
  margin: auto;

  --spinner-color: var(--color-yellow);
}
