.SettingsBlock__Wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: var(--color-bg-light);
  border-radius: 8px;
}

.SettingsBlock__Content {
  width: 430px;
  padding: 35px 40px;
}

.SettingsBlock__Content_left {
}

.SettingsBlock__Content_right {
}

.SettingsBlock__Title {
  font-size: 22px;
  color: var(--color-black);
  letter-spacing: 0;
  line-height: 30px;
}

.SettingsBlock__Description {
  width: 312px;
  font-size: 16px;
  color: #333333;
  line-height: 24px;
  white-space: pre-wrap;
}
