.wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
}

.iconSlot {
}

.textSlot {
}
