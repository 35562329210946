.MembersPageContent__Wrapper {
}

.MembersPageContent__LabelSlot {
  padding: 35px 22px 21px 22px;
}

.MembersPageContent__BodySlot {
  display: grid;
  gap: 5px;
}
