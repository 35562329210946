.FeedItem {
  background-color: transparent;
  box-sizing: border-box;
  border-left: 2px solid transparent;
}

.FeedItem_active {
  background-color: var(--color-bg-light);
  border-left-color: var(--color-yellow);
}
