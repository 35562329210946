.window {
  display: flex;
  flex-direction: column;
  padding: 40px 44px 0;
}

.title {
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
}

.content {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 16px 0 8px;
}

.footer {
  padding: 28px 0;
}
