.ReviewItem__Wrapper {
  position: relative;
  width: 100%;
  padding: 24px 24px 24px 22px;
  text-align: left;
  border-left: 2px solid transparent;
}

.ReviewItem__Wrapper_selected {
  background-color: var(--color-bg-light);
  border-left: 2px solid var(--color-yellow);
}

.ReviewItem__Wrapper_accent .ReviewItem__Status {
  color: var(--color-red);
}

.ReviewItem__Wrapper_secondary .ReviewItem__Status {
  color: var(--color-black);
}

.ReviewItem__Title {
}

.ReviewItem__Wrapper_selected .ReviewItem__Title {
  font-weight: 600;
}

.ReviewItem__Date {
  font-size: 12px;
  line-height: 18px;
  color: var(--color-gray);
  margin-top: 10px;
}

.ReviewItem__Status {
  position: absolute;
  float: right;
  right: 24px;
  bottom: 24px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
}
