.ArticleEditorFooter__Template {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.ArticleEditorFooter__Wrapper {
  display: flex;
  align-items: baseline;
  padding: 26px;
}

.ArticleEditorFooter__SubmitDraftButton {
}

.ArticleEditorFooter__SubmitButton {
  margin-left: 20px;
}

.ArticleEditorFooter__CancelButton {
  margin-left: auto;
}

.ArticleEditorFooter__NotifyLabel {
  cursor: pointer;
}

.ArticleEditorFooter__NotifyText {
  margin-left: 20px;
  vertical-align: middle;
}
