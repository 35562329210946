.NewsFeedItem__Wrapper {
  width: 320px;
  padding: 0;
  background-color: white;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.2);
  transition: width 0.1s, box-shadow 0.1s;
}

.NewsFeedItem__Wrapper:not(:first-child) {
  margin-top: 36px;
}

.NewsFeedItem__Wrapper_selected {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  width: 340px;
}

.NewsFeedItem__Image,
.NewsFeedItem__ImagePlaceholder {
  width: 100%;
  height: 170px;
}

.NewsFeedItem__Image {
  display: block;
  object-fit: cover;
}

.NewsFeedItem__ImagePlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-bg-light);
}

.NewsFeedItem__Info {
  width: 270px;
  padding: 20px 0;
  text-align: left;
  margin: 0 auto;
}

.NewsFeedItem__Title {
  font-weight: 600;
  word-break: break-all;
  word-break: break-word;
}

.NewsFeedItem__TitlePlaceholder {
  color: var(--color-gray);
  font-weight: 600;
}

.NewsFeedItem__Subtitle {
  font-size: 12px;
  color: var(--color-yellow-text);
  margin-top: 10px;
}
