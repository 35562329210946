.bookingTimelineTrackLabelLayout {
  &__wrapper {
    width: var(--bookingPageTrackLabelWidth);
    height: var(--bookingPageTrackHeight);
    position: relative;
  }
  &__wrapper:nth-child(even) {
    background: rgba(245, 245, 245, 0.76);
  }

  &__titleSlot,
  &__subtitleSlot {
    padding: 0 4px;
    position: absolute;
    width: 100%;
    overflow: hidden;

    line-height: 18px;

    text-align: center;
    text-overflow: ellipsis;

    span {
      white-space: nowrap;
    }
  }
  &__titleSlot {
    top: 4px;
  }
  &__subtitleSlot {
    top: 18px;
  }
}
