.booking-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 0;
}

.booking-header-btn {
  --btn-padding: 4px;
  background-color: var(--color-white);
  border: none;

  line-height: 1em;

  &.active {
    background-color: var(--color-yellow);
  }
}

.add-booking-btn {
  --btn-padding: 0 44px;
  background-color: var(--color-yellow);
}

.show-timeline-settings-btn {
  margin: 0 16px;
}

.show-time-work-settings-btn {
}

.booking-header-reset-action {
  padding-inline: 15px;
  background-color: var(--color-white);
  border: none;
}
