.bookingTimelineReservationPopupLayout {
  &__wrapper {
    position: absolute;
    z-index: 0;

    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.12);
    background: var(--color-white);
    border-radius: 4px;
    padding: 8px 12px;
  }

  &__title {
  }

  &__details {
    margin-top: 2px;
    display: flex;
    align-items: center;
  }

  &__time {
  }

  &__participantsIcon {
    margin-left: 8px;
  }

  &__participantsCount {
    margin-left: 2px;
  }

  &__comment {
    margin-left: 8px;
  }
}
