.text {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.text--color_primary {
  color: var(--color-black);
}

.text--color_accent {
  color: var(--color-yellow-text);
}

.text--color_secondary {
  color: var(--color-gray);
}

.text--color_critical {
  color: var(--color-red);
}

.text--bold {
  font-weight: bold;
}

.text--size_xxs {
  font-size: 10px;
}

.text--size_xs {
  font-size: 12px;
}

.text--size_s {
  font-size: 14px;
}

.text--size_m {
  font-size: 16px;
}

.text--size_l {
  font-size: 22px;
}

.text--size_xl {
  font-size: 28px;
}

.text--weight_regular {
  font-weight: 300;
}

.text--weight_semi-bold {
  font-weight: 600;
}

.text--weight_bold {
  font-weight: 700;
}
