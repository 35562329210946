.wrapper {
  display: flex;
  grid-gap: 20px;
}

.dateInputSlot {
}

.timeInputSlot {
}
