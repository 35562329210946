.CustomSelect__Button {
  padding: 0;
  line-height: 24px;
  font-weight: var(--custom-select__button-font-weight);
  color: var(--custom-select__button-color);
}

.CustomSelect__Button_opened {
}

.CustomSelect__Button:disabled {
  color: var(--color-black);
  color: var(--custom-select__button-color);
}

.CustomSelect__Arrow {
  pointer-events: none;
  display: inline;
  vertical-align: middle;
  margin-left: 5px;
  transition: transform 0.2s;
}

.CustomSelect__Arrow * {
  stroke: currentColor;
}

.CustomSelect__Arrow_up {
  transform: rotate(180deg);
}

.CustomSelect__DropDown {
  left: -10px;
  margin-top: 10px;
  padding: 10px 0;
}

.CustomSelect__DropDown::before {
  left: 20px !important;
}

.CustomSelect__PopupUp,
.CustomSelect__PopupDown {
  white-space: initial;
  display: block;
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 10px 0;
}

.CustomSelect__PopupUp {
  bottom: calc(100% + 2px);
}

.CustomSelect__PopupDown {
  top: calc(100% + 2px);
}

.CustomSelect__DropDownWithoutArrow {
  min-width: min-content;
  width: 100%;
  padding: 0 0 10px;
  max-height: 380px;
  overflow: auto;
}

.CustomSelect__DropDownWithoutArrow::before {
  content: none !important;
}

.CustomSelect__Option {
  text-align: left;
  white-space: nowrap;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  cursor: pointer;
}

.CustomSelect__Option_selected {
  background-color: var(--color-yellow-hover);
}

.CustomSelect__Option:hover:not(.CustomSelect__Option_selected) {
  background-color: var(--color-bg-light);
}

.CustomSelect__FormFieldBordered {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  height: 44px;
  padding: 0 17px;
  border: 1px solid var(--color-border);
  border-radius: 4px;

  &.CustomSelect__Button_opened:not(:disabled),
  &:hover {
    border-color: var(--color-yellow);
  }
}
