.CompetitionNotes__Wrapper {
  padding: 32px 60px;
  height: 100%;
}

.CompetitionNotes__TextArea {
  border: 1px solid var(--color-border);
  outline: none;
  resize: none;
  width: 760px;
  height: 100%;
  padding: 48px;
  font-family: inherit;
  line-height: 24px;
  font-size: 16px;
}
