.window {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: 0;
  width: 724px;
  height: 100%;
}

.closeButtonSlot {
  position: absolute;
  top: 20px;
  left: 20px;

  button {
    color: var(--color-gray);
  }
  button:hover {
    color: var(--color-black);
  }
}

.body {
  display: flex;
  flex-direction: column;
  padding: 74px 65px;
  height: 100%;
  overflow: auto;
}

.footerSlot {
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1) inset;
}
