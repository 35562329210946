.ImageInput__Wrapper {
  position: relative;
  z-index: 1;
}

.ImageInput__Wrapper_1_1 {
  height: 414px;
  width: 414px;
}

.ImageInput__Wrapper_2_1 {
  height: 207px;
  width: 414px;
}

/* Input */

.ImageInput__Input {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.ImageInput__Input:not(:disabled) {
  cursor: pointer;
}

.ImageInput__ErrorMessage {
}

.ImageInput__FallbackWrapper {
  width: 100%;
  height: 100%;
  border: 2px dashed var(--color-border);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ImageInput__Input:hover ~ .ImageInput__FallbackWrapper {
  border-color: var(--color-yellow);
}

.ImageInput__Placeholder {
  margin-top: 30px;
  color: var(--color-gray);
  font-weight: 600;
}

.ImageInput__InfoMessage,
.ImageInput__ErrorMessage {
  margin-top: 8px;
  font-size: 14px;
}

.ImageInput__InfoMessage {
  color: var(--color-gray);
}

.ImageInput__ErrorMessage {
  color: var(--color-red);
}

/* Preview */

.ImageInput__Preview {
  width: 100%;
  height: 100%;
}

.ImageInput__PreviewImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: filter 0.2s;
}

.ImageInput__Wrapper:hover .ImageInput__PreviewImage {
  filter: brightness(80%);
}

.ImageInput__PreviewActions {
  position: absolute;
  z-index: 1;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ImageInput__Wrapper:not(:hover) .ImageInput__PreviewActions {
  display: none;
}

.ImageInput__PreviewAction {
  border: 2px solid white;
  stroke: white;
}
.ImageInput__PreviewAction:not(:first-child) {
  margin-left: 10px;
}
.ImageInput__PreviewAction:hover {
  background-color: white;
}
.ImageInput__PreviewAction:hover * {
  stroke: var(--color-black);
}

/* Preloader */

.ImageInput__Preloader {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
}

.ImageInput__Spinner {
  margin: auto;
  --spinner-color: white;
}
