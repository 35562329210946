.wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.inputSlot {
}

.dayLabelSlot {
}
