@media (min-width: 1024px) {
  .MemberTableItemLabel {
    --checkbox-border-color: var(--color-red);
  }

  .MemberTableItemLabel[data-checked='true'] {
    --checkbox-border-color: var(--color-gray);
  }
}

@media (max-width: 1024px) {
  .MemberTableItemLabel {
    --checkbox-size: var(--btn-size);
    --checkbox-bg: var(--color-red);
    --checkbox-color: var(--color-white);
    --checkbox-border-color: var(--checkbox-bg);

    --checkbox-unchecked-icon-display: block;
  }

  .MemberTableItemLabel[data-checked='true'] {
    --checkbox-bg: var(--color-gray-light);
    --checkbox-color: var(--color-black);

    --checkbox-color-hover: var(--color-black);
    --checkbox-bg-hover: var(--color-yellow);
  }
}
