@import './src/style/ui';
@import './src/components/shared/Checkbox.module';

.ApplicationsTable__Wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  --header-height: 60px;
}

.ApplicationsTable__Body {
  padding-bottom: 40px;
  @include mq-mobile {
    padding-bottom: 20px;
  }
}

.ApplicationsTable__Header,
.ApplicationsTable__Body {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  @include mq-mobile {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.ApplicationsTable__HeaderRow {
  display: flex;
  align-items: center;
  padding-top: 4px;
}

.ApplicationsTable__Header {
  height: var(--header-height);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: var(--color-white);
  position: sticky;
  top: 0;
  z-index: 1;
}

.ApplicationsTable__Body {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
  min-height: 0;
  box-sizing: border-box;
  width: 100%;
}

.ApplicationsTable__Cell {
  color: var(--color-black);
  white-space: nowrap;
  flex-shrink: 0;
  line-height: 24px;
  padding: 16px 0 16px 24px;
}

.ApplicationsTable__Cell:nth-child(1) {
  width: 70px;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}
.ApplicationsTable__Cell:nth-child(2) {
  width: 130px;
  padding-right: 0;
  padding-left: 0;
}
.ApplicationsTable__Cell:nth-child(3) {
  width: 166px;
}
.ApplicationsTable__Cell:nth-child(4) {
  width: 295px;
}
.ApplicationsTable__Cell:nth-child(5) {
  width: 140px;
  padding: 6px 0 6px 24px;

  position: relative;
  display: inline-flex;
  align-items: center;
}
.ApplicationsTable__Cell:nth-child(6) {
  padding-left: 8px;
  width: 80px;
}
/* .ApplicationsTable__Cell:nth-child(6) {
  width: 120px;
} */

.ApplicationsTable__Header .ApplicationsTable__Cell {
  text-transform: uppercase;
  font-family: inherit;
  font-weight: bold;
  color: var(--color-black);
  font-size: 12px;
  line-height: 18px;
  text-align: left;
}

.ApplicationsTable__Cell:last-child {
  text-align: center;
}

.ApplicationsTable__CardAuthorName {
  font-size: 28px;
  line-height: 42px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: bottom;
  z-index: 0;
  max-width: 100%;
  padding: 0 8px;
  margin-left: -8px;
  margin-right: -8px;
  font-weight: 600;

  &:hover,
  &:active {
    background: rgba(223, 223, 223, 0.5);
  }
}

.ApplicationsTable__CardClipboardButton {
  --btn-bg: var(--color-gray-light);

  position: relative;
  z-index: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  vertical-align: middle;
  color: inherit;
  text-decoration: none;
  height: var(--btn-size);
  min-width: var(--btn-size);
  border-radius: var(--btn-radius);
  font-size: 22px;
  line-height: var(--btn-line-height);
  font-weight: var(--btn-font-weight);
  padding: var(--btn-padding);
  background: var(--btn-bg);
  margin: 0 8px;

  &:hover {
    --btn-bg: var(--color-yellow);
  }
}

/* Group */

.ApplicationsTable__GroupTitle {
  font-size: 16px;
  line-height: 24px;
  padding: 35px 22px 24px 22px;
  font-weight: 600;
  color: var(--appl-table-title-color);
  @include mq-desktop-l-max {
    font-size: 28px;
    line-height: 42px;
    padding: 34px 20px 24px 20px;
  }
}

.ApplicationsTable__GroupFallback {
  padding: 20px;
  color: var(--color-gray);
  width: 940px;
}

.ApplicationsTable__GroupContent {
  display: flex;
  flex-direction: column;
  gap: 5px;

  @include mq-tablet-min {
    align-items: flex-start;
  }
}

/* Select */

.ApplicationsTable__Select_new {
  --custom-select__button-color: var(--color-red);
}
.ApplicationsTable__Select_inProcess {
  --custom-select__button-color: var(--color-black);
}
.ApplicationsTable__Select_noResponse {
  --custom-select__button-color: var(--color-gray);
}
.ApplicationsTable__Select_done {
  --custom-select__button-color: var(--color-gray);
}

.ApplicationsTable__Group {
  @include mq-desktop-l-max {
    --btn-size: 54px;
    --btn-radius: 8px;

    --checkbox-bg: var(--color-gray-light);
    --checkbox-color: var(--color-black);
    --checkbox-border-color: var(--checkbox-bg);
    --checkbox-size: var(--btn-size);
    --checkbox-radius: var(--btn-radius);

    --checkbox-unchecked-icon-display: block;
    --checkbox-color-hover: var(--color-black);
    --checkbox-bg-hover: var(--color-yellow);
  }
}

.ApplicationsTable__GroupNew {
  --call-btn-bg: var(--color-yellow-hover);
  --card-call-btn-bg: var(--color-yellow-hover);

  --call-btn-color: var(--color-black);
  --checkbox-color: var(--color-red);
  --checkbox-border-color: var(--checkbox-color);
  --appl-table-title-color: var(--color-red);

  @include mq-desktop-l-max {
    --checkbox-bg: var(--color-red);
    --checkbox-color: var(--color-white);
    --checkbox-border-color: var(--checkbox-bg);
  }
}
