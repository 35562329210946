.wrapper {
  display: flex;
  grid-gap: 18px;
}

.predefinedValueOption {
  width: 44px;
  height: 44px;
  border: 1px solid var(--color-gray-light);
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    border-color: var(--color-yellow);
  }

  &.selected {
    background-color: var(--color-yellow);
  }
}

.customValueInput {
  input {
    width: 44px;
    height: 44px;
    padding: 0 12px;
    border: 1px solid var(--color-gray-light);
    border-radius: 4px;
    text-align: center;

    &:hover {
      border-color: var(--color-yellow);
    }
  }

  &.selected {
    input {
      border-color: var(--color-yellow);
    }
  }
}
