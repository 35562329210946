.wrapper {
  display: flex;
  flex-direction: column;
}

.legend {
  display: flex;
  align-items: center;
  padding: 0 8px;
  margin: 6px 0;
  grid-gap: 12px;
}

.nameLabelSlot {
  width: 276px;
}

.visitorsCountLabelSlot {
}

.itemsSlot {
  display: flex;
  flex-direction: column;
}
