.FallbackScreen__Wrapper {
  height: 100%;
  width: 100%;

  display: grid;

  align-items: center;
  justify-content: center;
}

.FallbackScreen__Content {
  text-align: center;
}

.FallbackScreen__TitleSlot {
}

.FallbackScreen__DetailsSlot {
  margin-top: 20px;
  white-space: pre-wrap;
}

.FallbackScreen__ActionSlot {
  display: flex;
  flex-direction: column;
  width: 250px;
  max-width: 250px;
  margin: 23px auto;
  gap: 16px;
}
