@import 'variables';

$mobileDevicePointsMap: (
  'ipad3': 2,
  'galaxyS3': 2,
  'galaxyS4': 3,
  'galaxyS5': 3,
  'iphone4': 2,
  'iphone5': 2,
  'iphone6': 2,
  'iphone7': 2,
  'iphone8': 2,
  'iphone6+': 3,
  'iphone7+': 3,
  'iphone8+': 3,
  'iphoneX': 3
);

$devicePointsMap: (
  'ipad3': (
    768px,
    1024px
  ),
  'galaxyS3': (
    320px,
    640px
  ),
  'galaxyS4': (
    320px,
    640px
  ),
  'galaxyS5': (
    360px,
    640px
  ),
  'iphone4': (
    320px,
    480px
  ),
  'iphone5': (
    320px,
    568px
  ),
  'iphone6': (
    375px,
    667px
  ),
  'iphone7': (
    375px,
    667px
  ),
  'iphone8': (
    375px,
    667px
  ),
  'iphone6+': (
    414px,
    736px
  ),
  'iphone7+': (
    414px,
    736px
  ),
  'iphone8+': (
    414px,
    736px
  ),
  'iphoneX': (
    375px,
    812px
  )
);

@function getDeviceMinPoint($d) {
  @if $d {
    @return min-device-width;
  }
  @return min-width;
}

@function getDeviceMaxPointKey($d) {
  @if $d {
    @return max-device-width;
  }
  @return max-width;
}

@function getPixelRatio($deviceType) {
  $deviceRatio: map-get($mobileDevicePointsMap, $deviceType);

  @if $deviceRatio {
    @return $deviceRatio;
  }
  @return $deviceRatio;
}

@function getTabletPoint($deviceType, $pointType) {
  @if $pointType == low {
    @return 768px;
  }
  @if $pointType == high {
    @return 1024px;
  }
  @return 0px;
}

@function getMobilePoint($deviceType, $pointType) {
  $devicePoints: map-get($devicePointsMap, $deviceType);

  @if $pointType == low {
    @if $devicePoints and
      type_of($devicePoints) ==
      list and
      length($devicePoints) >
      1
    {
      @return nth($devicePoints, 1);
    }
    @return 320px;
  }
  @if $pointType == high {
    @if $devicePoints and
      type_of($devicePoints) ==
      list and
      length($devicePoints) >
      1
    {
      @return nth($devicePoints, 2);
    }
    @return 768px;
  }
  @return 0px;
}

@mixin desktop($styles: ()) {
  @content;
}

@mixin tablet($styles: ()) {
  $styles: map-merge(
    (
      landscape: false,
      portrait: false,
      device: null,
      // default disabled - means you can set style only for device
        deviceType: null,
      // iphone6+ , ipad3 etc.
    ),
    $styles
  );

  $landscape: map_get($styles, landscape);
  $portrait: map_get($styles, portrait);
  $device: map_get($styles, device);
  $deviceType: map_get($styles, deviceType);
  $low: getTabletPoint($deviceType, low) !default;
  $high: getTabletPoint($deviceType, high) !default;

  $deviceRatio: null;
  $deviceMaxPointKey: getDeviceMaxPointKey($device);
  $deviceMinPointKey: getDeviceMinPoint($device);

  @if $deviceType {
    $deviceRatio: getPixelRatio($deviceType);
  }

  $tabletPoints: (
    $deviceMaxPointKey: $high
  );
  $tabletPortraitPoints: ();
  $tabletLandscapePoints: ();

  @if $device {
    $tabletPoints: (
      $deviceMinPointKey: $low,
      $deviceMaxPointKey: $high
    );
    $tabletPortraitPoints: map_merge(
      $tabletPoints,
      (
        orientation: portrait
      )
    );
    $tabletLandscapePoints: map_merge(
      $tabletPoints,
      (
        orientation: landscape
      )
    );
  }

  @if $device and $deviceType and $deviceRatio {
    $tabletPoints: map_merge(
      $tabletPoints,
      (-webkit-device-pixel-ratio: $deviceRatio)
    );
    $tabletPortraitPoints: map_merge(
      $tabletPortraitPoints,
      (-webkit-device-pixel-ratio: $deviceRatio)
    );
    $tabletLandscapePoints: map_merge(
      $tabletLandscapePoints,
      (-webkit-device-pixel-ratio: $deviceRatio)
    );
  }

  @if $landscape and $portrait {
    @include mq(
      (
        points: $tabletPoints,
        split: true
      )
    ) {
      @content;
    }
  } @else {
    @if $portrait {
      @include mq(
        (
          points: $tabletPortraitPoints,
          split: true
        )
      ) {
        @content;
      }
    } @else if $landscape {
      @include mq(
        (
          points: $tabletLandscapePoints,
          split: true
        )
      ) {
        @content;
      }
    } @else {
      @include mq(
        (
          points: $tabletPoints,
          split: true
        )
      ) {
        @content;
      }
    }
  }
}

@mixin mobile($styles: ()) {
  $styles: map-merge(
    (
      landscape: false,
      portrait: false,
      device: false,
      // default disabled - means you can set style only for device
        deviceType: null,
      // ipad3, iphone4 etc.
    ),
    $styles
  );

  $landscape: map_get($styles, landscape);
  $portrait: map_get($styles, portrait);
  $device: map_get($styles, device);
  $deviceType: map_get($styles, deviceType);
  $low: getMobilePoint($deviceType, low) !default;
  $high: getMobilePoint($deviceType, high) !default;

  $deviceRatio: null;
  $deviceMaxPointKey: getDeviceMaxPointKey($device);
  $deviceMinPointKey: getDeviceMinPoint($device);

  @if $deviceType {
    $deviceRatio: getPixelRatio($deviceType);
  }

  $mobilePoints: (
    $deviceMaxPointKey: $high
  );
  $mobilePortraitPoints: ();
  $mobileLandscapePoints: ();

  @if $device {
    $mobilePoints: (
      getDeviceMinPoint($device): $low,
      getDeviceMaxPointKey($device): $high
    );
    $mobilePortraitPoints: map_merge(
      $mobilePoints,
      (
        orientation: portrait
      )
    );
    $mobileLandscapePoints: map_merge(
      $mobilePoints,
      (
        orientation: landscape
      )
    );
  }

  @if $device and $deviceType and $deviceRatio {
    $mobilePoints: map_merge(
      $mobilePoints,
      (-webkit-device-pixel-ratio: $deviceRatio)
    );
    $mobilePortraitPoints: map_merge(
      $mobilePortraitPoints,
      (-webkit-device-pixel-ratio: $deviceRatio)
    );
    $mobileLandscapePoints: map_merge(
      $mobileLandscapePoints,
      (-webkit-device-pixel-ratio: $deviceRatio)
    );
  }

  @if $landscape and $portrait {
    @include mq(
      (
        points: $mobilePoints,
        split: true
      )
    ) {
      @content;
    }
  } @else {
    @if $portrait {
      @include mq(
        (
          points: $mobilePortraitPoints,
          split: true
        )
      ) {
        @content;
      }
    } @else if $landscape {
      @include mq(
        (
          points: $mobileLandscapePoints,
          split: true
        )
      ) {
        @content;
      }
    } @else {
      @include mq(
        (
          points: $mobilePoints,
          split: true
        )
      ) {
        @content;
      }
    }
  }
}

@mixin mq-desktop-xl {
  :global(.mq-desktop-xl) & {
    @content;
  }
}

@mixin mq-desktop-l {
  :global(.mq-desktop-l) & {
    @content;
  }
}

@mixin mq-tablet {
  :global(.mq-tablet) & {
    @content;
  }
}

@mixin mq-mobile {
  :global(.mq-mobile) & {
    @content;
  }
}

@mixin mq-desktop-xl-min {
  :global(.mq-desktop-xl) & {
    @content;
  }
}

@mixin mq-desktop-l-min {
  :global(.mq-desktop-xl) & {
    @content;
  }
}

@mixin mq-tablet-min {
  :global(.mq-desktop-xl) &,
  :global(.mq-desktop-l) & {
    @content;
  }
}

@mixin mq-mobile-min {
  :global(.mq-desktop-xl) &,
  :global(.mq-desktop-l) &,
  :global(.mq-tablet) & {
    @content;
  }
}

@mixin mq-desktop-xl-max {
  :global(.mq-desktop-l) &,
  :global(.mq-tablet) &,
  :global(.mq-mobile) & {
    @content;
  }
}

@mixin mq-desktop-l-max {
  :global(.mq-tablet) &,
  :global(.mq-mobile) & {
    @content;
  }
}

@mixin mq-tablet-max {
  :global(.mq-mobile) & {
    @content;
  }
}

@mixin navigation-minimize {
  :global(.mq-tablet) &,
  :global(.mq-desktop-l) & {
    @content;
  }
}
