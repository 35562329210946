.SettingsPage__Wrapper {
  display: inline-block;
  padding: 55px 20px;
}

.SettingsPage__Title {
  font-size: 28px;
  font-weight: 600;
  color: var(--color-black);
  line-height: 42px;
  padding: 0 40px;
}

.SettingsPage__Content {
  display: grid;
  grid-gap: 20px;
  margin-top: 40px;
}
