.employeeEditModal__modalWindow {
  width: 416px;
  border-radius: 4px;
}

.employeeEditModal__modalFooter {
  padding: 32px 48px;
}

.employeeEditModal__contentWrapper {
  display: flex;
  flex-direction: column;
  padding: 36px 48px 4px;
}

.employeeEditModal__roleAndNumberSelectContainer {
  display: flex;
  justify-content: space-between;

  .employeeForm__legend:first-child {
    flex: 1;
  }
}

.employeeEditModal__roleSelect {
  margin-right: 24px;
}

.employeeEditModal__numberSelect {
  min-width: 100px;
}

.employeeEditModal__btnDivider {
  padding-top: 16px;
}
