.wrapper {
  display: flex;
  align-items: center;
  grid-gap: 9px;
}

.startTimeInputSlot {
}

.endTimeInputSlot {
}

.dashSlot {
}
