.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.titleLabel {
  margin-bottom: 12px;
}

.guestCountPanel {
  margin: 12px 0;
}

.dateAndTimePanel {
  margin: 12px 0;
}

.placePanel {
  margin: 12px 0;
}

.commentPanel {
  margin: 12px 0;

  textarea {
    width: 100%;
    min-height: 92px;
    padding: 10px 17px;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    overflow: hidden;

    &:hover {
      border-color: var(--color-border-hover);
    }

    &:focus {
      border-color: var(--color-yellow);
    }
  }
}

.guestWithoutReservationPanel {
  margin: 12px 0;
}
