.plusInCircleFigure {
  &__wrapper {
    border: 2px solid var(--color-yellow);
    border-radius: 50%;
    color: var(--color-yellow);

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: var(--color-yellow-hover);
      border: none;

      color: var(--color-black);
    }

    &--size_m {
      width: 32px;
      height: 32px;
    }
    &--size_l {
      width: 44px;
      height: 44px;
    }
  }
}
