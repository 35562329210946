.bookingTimelineHeaderLayout {
  &__wrapper {
    height: 48px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
  }

  &__iconSlot {
    margin-left: 35px;

    svg {
      transition: transform linear 0.2s;
    }
    &--collapsed {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &__titleSlot {
    margin-left: 12px;
  }
}
