.bookingTimelineTrackLayout {
  &__wrapper {
    height: var(--bookingPageTrackHeight);
    width: 100%;
    display: flex;
  }
  &__wrapper:nth-child(even) {
    background: rgba(245, 245, 245, 0.76);
  }

  &__itemsSlot {
    width: 100%;
    display: flex;
  }
}
