.CustomDatePicker__Wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 2;
  background-color: white;
  border-radius: 2px;
  height: 306px;
  border-top: 4px solid white;
  border-bottom: 4px solid white;
  overflow-y: auto;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  color: var(--color-black);
}

/*
TODO: разобраться, почему указатель не отрисовывается 

.CustomDatePicker__Wrapper::before {
  content: '';
  position: absolute;
  top: -5px;
  left: 20px;
  background-color: white;
  box-shadow: 1px -1px 3px 0 rgba(0, 0, 0, 0.05);
  width: 10px;
  height: 10px;
  transform: rotate(-45deg);
}

.CustomDatePicker__Wrapper::after {
  content: '';
  position: absolute;
  top: 0;
  left: 10px;
  background-color: white;
  width: 30px;
  height: 10px;
} */

.CustomDatePicker__MonthGroup {
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
}

.CustomDatePicker__MonthName {
  overflow: hidden;
  padding: 15px 10px 15px 18px;
  width: 100px;
  font-size: 12px;
}

.CustomDatePicker__MonthDates {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 2px;
  padding: 10px 17px 10px 10px;
  font-size: 12px;
}

.CustomDatePicker__DateButton {
  height: 23px;
  width: 23px;
  border: none;
  outline: none;
  background: none;
  padding: 0;
  cursor: pointer;
  border-radius: 50%;
  color: inherit;
}

.CustomDatePicker__DateButton:disabled {
  color: var(--color-gray);
  cursor: default;
}

.CustomDatePicker__DateButton:hover:not(.CustomDatePicker__DateButton_current):not(:disabled),
.CustomDatePicker__DateButton:focus:not(.CustomDatePicker__DateButton_current):not(:disabled) {
  background-color: var(--color-bg-light);
}

.CustomDatePicker__DateButton_current {
  background-color: var(--color-yellow);
  color: var(--color-black);
}
