.wrapper {
  background-color: var(--color-bg-light);
  border-radius: 8px;

  &:hover {
    box-shadow: inset 0 0 0 1px #dfdfdf, 0 0 0 1px #dfdfdf;
  }
  &:focus {
    box-shadow: inset 0 0 0 1px #f7c631, 0 0 0 1px #f7c631 !important;
  }
}

.wrapper__theme_mobile {
  height: 184px;
  padding: 16px;
  display: grid;
  align-content: space-between;
  grid-template-areas:
    'iconSlot numberSlot . . . timeSlot'
    'nameSlot nameSlot nameSlot nameSlot nameSlot nameSlot'
    'phoneCallControlSlot phoneCallControlSlot phoneCallControlSlot phoneNumberCopyingControlSlot bookingControlSlot statusControlSlot';
  grid-template-columns: 40px auto auto auto;

  .iconSlot {
    margin-right: auto;
  }
  .numberSlot {
    margin-right: auto;
  }
  .timeSlot {
    margin-left: auto;

    text-align: right;
  }
  .nameSlot {
    text-align: left;
  }

  .phoneCallControlSlot {
    margin-right: auto;

    width: 100%;

    > button {
      width: 100%;
      max-width: 100%;
    }
  }
  .phoneNumberCopyingControlSlot {
    margin-right: auto;
  }

  .statusControlSlot {
    margin-left: auto;

    > * {
      width: 100%;
    }
  }
}

.wrapper__theme_desktop {
  width: 924px;
  height: 56px;
  padding-inline: 16px 32px;
  display: grid;
  grid-template-areas: 'iconSlot numberSlot phoneNumberCopyingControlSlot nameSlot timeSlot phoneCallControlSlot bookingControlSlot statusControlSlot';
  align-items: center;

  .numberSlot {
    margin-left: 20px;
    width: 120px;
    text-align: left;
  }
  .phoneNumberCopyingControlSlot {
  }
  .timeSlot {
    margin-left: auto;
  }
  .nameSlot {
    margin-left: 10px;
    width: 270px;
    text-align: left;
  }
  .phoneCallControlSlot {
    margin-left: 10px;
  }
  .bookingControlSlot {
  }
  .statusControlSlot {
    margin-left: 10px;
  }
}

.iconSlot {
  grid-area: iconSlot;
}

.numberSlot {
  grid-area: numberSlot;
}

.timeSlot {
  grid-area: timeSlot;

  display: flex;
  flex-direction: column;
}

.nameSlot {
  grid-area: nameSlot;
}

.phoneCallControlSlot {
  grid-area: phoneCallControlSlot;
}

.phoneNumberCopyingControlSlot {
  grid-area: phoneNumberCopyingControlSlot;
}

.bookingControlSlot {
  grid-area: bookingControlSlot;
}

.statusControlSlot {
  grid-area: statusControlSlot;
}
