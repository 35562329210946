.CompetitionsFeed__Wrapper {
  --wrapper-height: 100%;
  --header-height: 60px;
  --content-height: calc(var(--wrapper-height) - var(--header-height));

  position: relative;
  z-index: 0;
  height: var(--wrapper-height);
}

/* Header */

.CompetitionsFeed__Header {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--header-height);
  padding: 0 14px 0 26px;
}

/* Content */

.CompetitionsFeed__Scrollable {
  height: var(--content-height);
}

.CompetitionsFeed__Items {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Create item button */

.CompetitionsFeed__CreateItemButton {
  margin-left: auto;
  border-color: transparent;
  border-radius: 0%;
}

.CompetitionsFeed__CreateItemButton:hover {
  background-color: var(--color-yellow-hover);
  border-color: var(--color-yellow-hover);
  color: var(--color-black);
}

/* Preloader */

.CompetitionsFeed__Preloader {
  --spinner-color: var(--color-yellow);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-grow: 1;
  margin: 36px 0;
}

/* Fallback */

.CompetitionsFeed__Fallback {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: var(--color-gray);
  font-weight: 600;
}
